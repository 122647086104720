import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAmplifyAPI } from "views/GraphQL/gqApi";
import api from "../../../api";
import { formatLocations, formatSite } from "../../../utils";
import { LOCATION, LOCATION_FECTH, USER_LOCATION_FECTH, IMPORT_LCOATION } from "./locationsConstants";
// Async thunk to fetch all locations

export const fetchAllLocations = createAsyncThunk(LOCATION_FECTH, async (param, { getState }) => {
  const clients = getState().clients.clients;

  const models = await api.getSites(param);
  return models;
});

//Async thunk to Fetch user location
export const fetchUserLocation = createAsyncThunk(USER_LOCATION_FECTH, async (param, { getState }) => {
  const user = param.user;
  const location = await api.getUserSiteData(user?.sub);
  return location;
});

// Async thunk to create a location
export const createLocationAsync = createAsyncThunk("location/createLocation", async (location, { getState }) => {
  const loginUser = getState().auth.user;
  const response = await api.createLocations(location, loginUser);
  return response;
});
export const createLabClient = createAsyncThunk("location/createLabClient", async (location, { getState }) => {
  const loginUser = getState().auth.user;
  const response = await api.createSite(location, loginUser);
  const formattedSite = formatSite(response?.data?.createSite);
  return formattedSite;
});

export const importLocationAsync = createAsyncThunk(IMPORT_LCOATION, async (location, { getState }) => {
  const loginUser = getState().auth.user;
  const params = location.map((loc) => ({
    ...loc,
    createdBy: loginUser.sub,
    createdByName: loginUser.name,
  }));
  const response = await api.importLabClient(params);
  return response;
});

// Async thunk to update a location
export const updateLocationAsync = createAsyncThunk(
  "location/updateLocation",
  async ({ locationId, updatedlocation }) => {
    const response = await api.updateLocation(locationId, updatedlocation);
    return response;
  }
);
export const updateLabClient = createAsyncThunk(
  "location/updateLabClient",
  async (data, { getState, rejectWithValue }) => {
    try {
      const loginUser = getState().auth.user;
      const response = await api.updateSite(data, loginUser);
      return formatSite(response?.data?.updateSite);
    } catch (error) {
      console.error("Error in updateLabClient:", error);
      return rejectWithValue(error.response?.data || "Unexpected error occurred");
    }
  }
);
export const updateLabClientNotes = createAsyncThunk("location/updateLabClientNotes", async (data, { getState }) => {
  const loginUser = getState().auth.user;

  const response = await callAmplifyAPI("/labclient/note", { site: data });
  return formatSite(response?.data?.updateSite);
});

// Async thunk to delete a location
export const deleteLocationAsync = createAsyncThunk("location/deleteLocation", async (locationId) => {
  const response = await api.deleteLocation(locationId);
  return response;
});

const locationSlice = createSlice({
  name: LOCATION,
  initialState: {
    locations: [],
    refLabs: [],
    userLocation: null,
    showLocationTestModal: false,
  },
  reducers: {
    updateLocationOnSubscription: (state, action) => {
      let updatedlocation = action.payload.model;
      if (updatedlocation) {
        updatedlocation = formatSite(updatedlocation);
        const existinglocationIndex = state.locations.findIndex((location) => location.id === updatedlocation.id);
        console.log('updateLocationOnSubscription', existinglocationIndex, updatedlocation);
        if (existinglocationIndex !== -1) {
          if(updatedlocation.isArchive){
            state.locations = state.locations.filter((location) => location.id !== updatedlocation.id);
          }else{
            state.locations[existinglocationIndex] = updatedlocation;
          }
        } else if (action.payload.event === "INSERT") {
          state.locations.unshift(updatedlocation);
        } 
      }
    },
    setLocationTestModal: (state, action) => {
      state.showLocationTestModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        const list = action.payload;
        state.locations = list?.filter((item) => item.siteType !== "lab");
        state.refLabs = list?.filter((item) => item.siteType === "lab");
      })
      .addCase(createLocationAsync.fulfilled, (state, action) => {
        state.locations.push(action.payload);
      })
      .addCase(createLabClient.fulfilled, (state, action) => {
        const updatedlocation = action.payload;
        if (updatedlocation) {
          const existinglocationIndex = state.locations.findIndex((location) => location.id === updatedlocation.id);
          if (existinglocationIndex !== -1) {
            state.locations[existinglocationIndex] = updatedlocation;
          } else {
            state.locations.push(action.payload);
          }
        }
      })
      .addCase(updateLocationAsync.fulfilled, (state, action) => {
        const updatedlocation = action.payload;
        if (updatedlocation) {
          const existinglocationIndex = state.locations.findIndex((location) => location.id === updatedlocation.id);
          if (existinglocationIndex !== -1) {
            state.locations[existinglocationIndex] = updatedlocation;
          }
        }
      })
      .addCase(updateLabClient.fulfilled, (state, action) => {
        const updatedlocation = action.payload;
        if (updatedlocation) {
          const existinglocationIndex = state.locations.findIndex((location) => location.id === updatedlocation.id);
          if (existinglocationIndex !== -1) {
            state.locations[existinglocationIndex] = updatedlocation;
          }
        }
      })
      .addCase(updateLabClientNotes.fulfilled, (state, action) => {
        const updatedlocation = action.payload;
        if (updatedlocation) {
          const existinglocationIndex = state.locations.findIndex((location) => location.id === updatedlocation.id);
          if (existinglocationIndex !== -1) {
            state.locations[existinglocationIndex] = updatedlocation;
          }
        }
      })
      .addCase(deleteLocationAsync.fulfilled, (state, action) => {
        const deletedlocationId = action.payload;
        state.locations = state.locations.filter((location) => location.id !== deletedlocationId);
      })
      .addCase(fetchUserLocation.fulfilled, (state, action) => {
        state.locations = [action.payload];
        state.userLocation = action.payload;
      });
  },
});
export const { updateLocationOnSubscription, setLocationTestModal } = locationSlice.actions;

export default locationSlice.reducer;
