import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { ReactSelectCustomContainer } from "components/CustomReactSelect/ReactSelectCustomOptionContainer";

import "../../../src/tableStyling.css";

const ConfirmationModal = (props) => {
  const {
    title,
    message,
    handleConfirm,
    isPreRegister,
    handleClose,
    cssClass,
    note,
    selectedOptions,
    options,
    handleOptions,
    isSelectOptions,
    reasonObj,
  } = props;
  const [value, setValue] = useState("");

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose ? handleClose(false) : handleConfirm(false);
      }}
      centered
      size={"md"}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {message}
        </Modal.Title>
        {note && note.label && (
          <div className="w-100 mt-2">
            <label className="profile-title">{note.label}</label>
            <div className="mb-3">
              <textarea
                className="form-control reason-textarea w-100"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              ></textarea>
            </div>
          </div>
        )}
        {reasonObj && (
          <div className="w-100 mt-2">
            <div className="mb-3">
              <textarea
                className="form-control reason-textarea w-100"
                value={reasonObj?.cancelTestData.reason}
                onChange={(e) => reasonObj.setCancelTestsData({ ...reasonObj?.cancelTestData, reason: e.target.value })}
              ></textarea>
            </div>
          </div>
        )}

        {isSelectOptions && (
          <Select
            options={options}
            blurInputOnSelect={true}
            menuPlacement="auto"
            className="w-100 siteSelector site-select-test"
            placeholder="Select Source"
            value={selectedOptions}
            onChange={(e) => handleOptions(e)}
            components={{ ValueContainer: ReactSelectCustomContainer }}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="modalButtons"
          variant="secondary"
          onClick={() => {
            handleConfirm(false);
          }}
        >
          {isPreRegister ? "No" : "Go back"}
        </Button>
        <Button
          className="modalButtons"
          variant="primary"
          onClick={() => {
            handleConfirm(true, value);
          }}
        >
          {isPreRegister ? "Yes" : " Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
