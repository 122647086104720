import api from "api";
import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";

const OrderTestSelector = ({ onChange, value = null, dataIds,labClientTest }) => {


  const [options, setOptions] = useState([]);

  const formatOptions = (items = []) =>
    items.map(({ id, name, ...rest }) => ({
      ...rest,
      id,
      name,
      label: name,
      value: id,
    }));

  const getTestOptions = useCallback(async () => {
    try {
      const [testTypes, panels] = await Promise.all([api.getTestTypes(), api.getPanels()]);

      const filteredTestItem = formatOptions(testTypes?.filter((item) => !item.isPanelOnly));
      const filteredPanelItem = formatOptions(panels);
      let mergedOption = [...filteredTestItem, ...filteredPanelItem].filter(
        (item) => !dataIds.includes(item.id)
      );

      if (labClientTest && labClientTest.length > 0) {
        mergedOption = mergedOption.filter((item) => labClientTest.includes(item.id));
      }
      
      setOptions(mergedOption.sort((a, b) => a?.name?.localeCompare(b?.name)));
    } catch (error) {
      console.error("Error fetching test options:", error);
    }
  }, []);

  useEffect(() => {
    getTestOptions();
  }, [getTestOptions,dataIds]);

  return (
    <Select
      options={options}
      blurInputOnSelect
      menuPlacement="auto"
      className="w-100 siteSelector"
      placeholder="Select Test"
      value={value}
      onChange={onChange}
    />
  );
};

export default OrderTestSelector;
