import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import { Card, Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import GQAPI from "views/GraphQL/gqApi";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import { useLocation } from "react-router-dom";
import { Storage } from "aws-amplify";
import "react-activity/dist/Bounce.css";
import { formatTest, sortTestList, sortingFilterInLC, isAllowToArchive, formattedString, env } from "utils";
import FilterSelect from "components/FilterSelect";
import ContactInfo from "components/Modal/ContactInfo";
import PGPagination from "components/Pagination/PGPagination";
import { AppContext } from "context/app-context";
import { PAGE_LIMIT, TEST_HEADER_TYPE, ADMIN_USER } from "constant";
import PDFLayout from "components/PDF/PDFLayout";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ExportToExcelData from "components/ExportToExcelData";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import moment from "moment";
import { debounce } from "debounce";
import { useInitialRender } from "components/useInitialRender";
import Loader from "components/Loader/Loader";
import Icon from "components/Icon";
import { stringConstant, CONFIG } from "constant";
import ChangeResultModal from "components/Modal/ChangeResultModal";
import { UN_RESULT } from "constant";
import { exportToCalRedie } from "utils";
import { downloadDataAsCSV } from "utils";
import CalRedieLogsModal from "components/Modal/CalredieLogsModal";
import { setSelectedTestForResult } from "store/features/resultsTests/resultsTestAction";
import { useDispatch } from "react-redux";
import MainTable from "components/Table/MainTable";
import { PERSONALISATION_KEY } from "constant";
import { personalisationInLC } from "utils";
import { TABLE_QUICK_TOOLS, COMPLETED_TESTS } from "constant";
import ResultCell from "views/GraphQL/TestListCompletedTest/ResultCell";
import SequenceNo from "components/SequenceNo";
import { capitalizeLetter } from "utils";
import Status from "components/Status";
import { Link } from "react-router-dom";
import RedirectLink from "components/RedirectLink";
import { getValidName } from "utils";
import PersonalizationModal from "components/Modal/personalizationModal";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import DocumentPreviewModal from "components/Modal/DocumentPreviewModal";
import { LAB_ID, BIO_LAB_ID } from "constant";

import { isLocalhost } from "utils";
import { showResultPdf } from "store/features/resultPdf/reslutPdfSlice";
import { setLoader } from "store/features/general/generalSlice";
import { setMessage } from "store/features/general/generalAction";
import { MESSAGE_MODES } from "constant";
import { setSimpleResultModal, setTestForResult } from "store/features/resultsTests/resultsTestSlice";

const TestListAdminApi = () => {
  const [totalRecord, setTotalRecord] = useState(0);
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemToDelete, setItemToDelete] = useState({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [openUploadLogsModal, setOpenUploadLogsModal] = useState(false);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const permission = useSelector(userGivenPermission);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [docName, setDocName] = useState("");

  const [personalize, setPersonalize] = useState([]);

  const dispatch = useDispatch();

  const history = useHistory();

  const { filterDates } = TimeFilter;

  const location = useLocation();

  const initialRender = useInitialRender();
  const appContext = useContext(AppContext);

  const searchTerms = CONFIG.isWhiteLabel
    ? [
        "First Name",
        "Last Name",
        "Date of Birth",
        "Email",
        "Phone",
        `${stringConstant.show}`,
        "Status",
        "Result",
        "Test Type",
        `${stringConstant.sequenceNo}`,
        "Tester",
        "Result Sent",
        "Submitted to H&S",
        "Referring Physician",
        "Arkstone Status",
      ]
    : [
        "First Name",
        "Last Name",
        "Date of Birth",
        "Email",
        "Phone",
        "Client",
        `site_name`,
        "Lab",
        "Status",
        "Result",
        "Test Type",
        `${stringConstant.sequenceNo}`,
        "Tester",
        "Result Sent",
        "isVaccinated",
        "White Glove",
        "Pattern",
        "Capacity",
        "Arkstone Status",
      ];

  const selectedItem = CONFIG.isWhiteLabel
    ? [
        "resultDate",
        "firstName",
        "lastName",
        "dob",
        "email",
        "phoneNumber",
        "siteID",
        "result",
        "test_type",
        `${stringConstant.accession_no}`,
        "emailSend",
      ]
    : [
        "createdAt",
        "firstName",
        "lastName",
        "dob",
        "email",
        "phoneNumber",
        "clientID",
        "siteID",
        "labID",
        "result",
        "test_type",
        `${stringConstant.accession_no}`,
        "tester_name",
        "emailSend",
        "barcode",
        "isVaccinated",
        "employeeType",
        "whiteGlove",
        "patternTestAnswer",
      ];

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);
    if (!infoObj) return;
    setMoreInfo({
      type,
      name: infoObj.name,
      contact: infoObj.contact_name,
      phone: infoObj.contact_phone || infoObj.phone_number,
      email: infoObj.contact_email,
    });
    setModalShow(true);
  };

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadTestFromPG(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  // const showDocumentPreview = async (test) => {
  //   setIsPreviewModalOpen(true);
  //   const result = await Storage.get(`results/${env}/${test?.clientID}/${test?.barcode}.pdf`, {
  //     bucket: "employee-external-tests",
  //     download: false,
  //     contentDisposition: "inline",
  //     Bucket: "employee-external-tests",
  //     contentType: "application/pdf",
  //   });

  //   setFileUrl(result);
  //   setFileType("pdf");
  // };

  const showResultPdfLocal = async (test) => {
    handleSaveFilter();
    const testDetails = await API.getTestByID(test.id, "test");
    const t = testDetails;
    const formatedData = formatTest(t);
    if (testDetails?.arkstoneID) {
      setDocName(formatedData.name);
      await showDocumentPreview(test);
      return;
    }

    setTestPdf(formatedData);
    t.empID = testDetails?.employee?.schrID || `BRM-002-${testDetails?.employee?.mdID}` || id;
    setTestsLab(t.lab);
    setShowPdf(true);
  };

  const displayPdf = async (test) => {
    dispatch(showResultPdf(test));
    // if (test.labID === LAB_ID.alta) {
    //   dispatch(showResultPdf(test));
    // } else if (test.labID === LAB_ID.discovery || isLocalhost()) {
    //   await showResultPdfLocal(test);
    // } else {
    //   await showResultPdfLocal(test);
    // }
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    if (pageNo > 1) {
      loadTestFromPG(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter);
    }
  }, [pageNo]);

  useEffect(() => {
    setLabs(appContext.labs);
  }, [appContext.labs]);

  useEffect(() => {
    const savedFilters = appContext.testFilter;
    if (savedFilters && savedFilters.location === "alltests") {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
      if (Object.keys(savedFilters.timeFilter).length > 0) {
        setTimeFilter(savedFilters.timeFilter);
      }
    }
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.alltests.sortBy) {
      setSortDescending(sortingFilters.alltests.sortDescending);
      setSortBy(sortingFilters.alltests.sortBy);
    }

    setPersonalize(personalisationInLC.get(PERSONALISATION_KEY.COMPLETED_TEST));
  }, []);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["alltests"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      if (location.state.term === "Lab") setLabs(location.state.models);

      setShowFilter(true);

      let filterTerm = location.state.term;
      let filterTermName = location.state.name;

      if (filterTerm === "Show") {
        const obj = location.state.models.find((c) => c.name === filterTermName);
        if (obj) {
          filterTerm = "ShowID";
          filterTermName = obj.id;
        }
      }

      if (location.state.positiveTests) {
        let resultValue = "positive";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "fail";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: "positive",
        });
      } else if (location.state.negativeTests) {
        let resultValue = "negative";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "pass";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: resultValue,
        });
      } else {
        setFilter({ ...filter, [filterTerm]: filterTermName });
      }
      setTriggerFilter(true);
      setTimeout(() => setShowFilter(false), 1);
    }
  }, [location.state]);

  useEffect(() => {
    if (initialRender) return;

    if (appContext.testObject && appContext.testObject !== 0) {
      const eventType = appContext.testObject.opType;
      const model = formatTest(appContext.testObject.element);
      if (model.status !== "Processed" && model.status !== "Completed") return;
      const newList = [...filteredTests];
      if (eventType === "INSERT") {
        newList.unshift(model);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1, model);
        } else {
          newList.unshift(model);
        }
      } else if (eventType === "DELETE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1);
        }
      }

      const testAfterFilter = filterDates(nestedFilter([...newList], filter), timeFilter);
      if (filteredTests.length < testAfterFilter.length) {
        setTotalRecord(totalRecord + 1);
      }
      refreshTestList(testAfterFilter);
      // setTests(testAfterFilter);
    }
  }, [appContext.testObject]);

  const updateAcknowledgeBy = async () => {
    const positiveTests = filteredTests
      .filter((c) => checkboxes.includes(c.id) && c.result === "Positive" && !c.isReview)
      .map((m) => ({ id: m.id, userID: appContext.user?.sub, userName: appContext.user?.name }));
    try {
      setLoading(true);
      await API.acknowledgedPositiveTest(positiveTests);
      appContext.showSuccessMessage(`${positiveTests.length} Test(s) Verified Successfully`);
      setCheckboxes([]);
      setLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, triggerFilter, timeFilter, sortBy, sortDescending]);

  const formatFilters = (params, dateFilter, page, orderBy, orderByDirection) => {
    const filterParam = params;
    Object.assign(filterParam, { page: pageNo, status: "Processed" });

    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start)
        Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
      if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
    }

    if (orderBy) {
      Object.assign(filterParam, { orderBy, orderByDirection });
    }
    return filterParam;
  };

  const loadTestFromPG = async (params, dateFilter, page, orderBy, orderByDirection) => {
    setLoading(true);

    const filterParam = formatFilters(params, dateFilter, page, orderBy, orderByDirection);

    const pgList = await API.getTestListFromPG(filterParam);
    setLoading(false);
    const ttlTests = tests.concat(pgList.rows.map((t) => formatTest(t)));
    if (pageNo === 1) {
      setTotalRecord(pgList.total);
    }
    setTests(ttlTests);
    refreshTestList(ttlTests);
  };

  const handleExportCalrediePatient = async () => {
    try {
      setLoading(true);
      if (checkboxes.length === 0) {
        const resFilePathc = await API.exportToCalRedieData({ date: moment().format("YYMMDD") });

        const resut = await Storage.get(`csvfile/${resFilePathc}`, {
          bucket: "employee-external-tests",
        });

        const response = await fetch(resut);

        const blob = await response.blob();

        if (blob.size === 0) {
          appContext.showErrorMessage("No Pending Data For Submit to Calredie");
          setLoading(false);
          return;
        }
        saveAs(blob, `biomobilelab_${resFilePathc}`);
      } else {
        let tests = filteredTests.filter((f) => f.result && checkboxes.includes(f.id));

        const uniqueSites = [...new Set(tests.map((test) => test.siteID))];
        const siteToProviderMap = {};

        if (uniqueSites.length > 0) {
          const promiseArray = uniqueSites.map(async (siteID) => {
            const defaultProvider = await API.getDefaultProvider(siteID);
            siteToProviderMap[siteID] = defaultProvider;
          });
          await Promise.allSettled(promiseArray);
        }

        tests = filteredTests.filter((f) => {
          const demo = JSON.parse(f.employee_demographics || "{}");
          const ins = (demo.insurance_name || "").toLowerCase();
          // return f.result && checkboxes.includes(f.id) && !(ins.includes("medicare") || ins.includes("medicaid"));
          return f.result && checkboxes.includes(f.id);
        });
        if (tests.length === 0) {
          appContext.showErrorMessage("All tests results Already Submitted!");
          setLoading(false);
          return;
        }
        const labReceord = appContext.labs.find((f) => f.id === CONFIG.whiteLabelID);

        const results = exportToCalRedie(tests, labReceord, siteToProviderMap);
        downloadDataAsCSV(results, `biomobilelab_${moment().toISOString()}`);
      }
      setCheckboxes([]);
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
    }
  };

  const handleUploadLogs = () => {
    setOpenUploadLogsModal(true);
  };

  const afterDeletedTest = (testList) => {
    const tmpList = filteredTests.filter((t) => testList.findIndex((f) => f.id === t.id) === -1);
    setFilteredTests(tmpList);
    setTotalRecord(totalRecord - testList.length);
    setCheckboxes([]);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "emailSend") {
          return obj.emailSend !== null && obj.emailSend == filters[key];
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      return;
    }
    if (Object.keys(itemToDelete).length > 0) {
      await API.deleteWrongTest(itemToDelete.id);
      setItemToDelete({});
    }
  };

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "alltests", filter, timeFilter, sortBy, sortDescending });
  };

  const handleCheckboxChange = (e, row) => {
    const filteredList = checkboxes.filter((c) => c !== row.id);
    if (e.target.checked) {
      filteredList.push(row.id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredTests.map((t) => t.id) : []);

  const handleConfirmation = async () => {
    setOpenConfirmation(false);
    const updateSelectedTestResult = tests.filter((test) => checkboxes.includes(test.id));
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      return;
    }
    setLoading(true);

    try {
      if (!loading) setLoading(true);
      const testList = updateSelectedTestResult.map((t) => {
        return {
          id: t.id,
          sequenceNo: t.sequenceNo,
          userName: appContext.user.name,
        };
      });
      await GQAPI.unResultTests(testList);
      setFilteredTests(filteredTests.filter((test) => !checkboxes.includes(test.id)));
      setTotalRecord(totalRecord - ttlTest);
      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} has been ${UN_RESULT} successfully`);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(`Fail to ${UN_RESULT} the Test`);
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const clearSelection = () => {
    setAllSelected(false);
    setCheckboxes([]);
    setLoading(false);
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredTests.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };

  const handleSendEmail = async () => {
    const selectedTest = filteredTests.filter((test) => checkboxes.includes(test.id));
    if (!selectedTest.length) {
      return;
    }
    const params = selectedTest.map((t) => {
      return {
        id: t.id,
        sequenceNo: t.sequenceNo,
      };
    });
    await appContext.sendEmailToCrew(params, "Email");
  };

  const downloadRequisitionFile = async () => {
    try {
      await appContext.downloadRequisition({ allSelected, filter, timeFilter, checkboxes, filteredTests });
    } catch (error) {
      console.log("error", error);
      appContext.showErrorMessage("Fail to downlaod");
    }
  };
  const handleExportLabGen = async () => {
    await appContext.sendToLab(filteredTests, checkboxes);
    setCheckboxes([]);
  };
  const handleExportBilling = async () => {
    try {
      setLoading(true);
      const data = await API.formatBioBillingCSV(filteredTests);

      await downloadDataAsCSV(data, `billing_dept_${moment().format("MM_DD_YYYY")}.csv`);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      appContext.showSuccessMessage(`Fail to download the file`);
    }
  };

  const handleShowResultModal = () => {
    const selectedTests = tests.filter((test) => checkboxes.includes(test.id));

    if (CONFIG.clientID === BIO_LAB_ID) {
      dispatch(setSimpleResultModal({ showSimpleModal: true, simpleTestList: selectedTests }));
      setCheckboxes([]);
      return;
    }
    if (selectedTests.length !== 1) return;

    if (selectedTests[0].sendToName) {
      dispatch(setTestForResult(selectedTests[0]));
    } else {
      dispatch(setSelectedTestForResult(selectedTests));
    }

    setCheckboxes([]);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = personalisationInLC.saveAs(data, PERSONALISATION_KEY.COMPLETED_TEST);
    setOpenPersonalizationModal(false);
    await API.saveUserPersonalisation(appContext.user.sub, personalisationData);
  };

  const customRenderTD = (item, test) => {
    const { itemKey } = item;
    const testId = test?.id;

    if (itemKey === "result") {
      return <ResultCell test={test} appContext={appContext} displayPdf={displayPdf} key={`result_${testId}`} />;
    }
    if (itemKey === "firstName") {
      return (
        <td className="table-column ellipsis" title={capitalizeLetter(getValidName(test.firstName))}>
          <RedirectLink pathname="order" value={{ name: test.orderID, term: "id" }}>
            {capitalizeLetter(getValidName(test.firstName))}
          </RedirectLink>
        </td>
      );
    }
    if (itemKey === "barcode") {
      return (
        <SequenceNo
          num={CONFIG.isWhiteLabel ? test.barcode : test.sequenceNo}
          test={test}
          showLogs={appContext.showLogs}
        />
      );
    }

    if (itemKey === "site_name") {
      return (
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("site", test.siteID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={formattedString(test.site_name)}
          >
            {formattedString(test.site_name)}
          </a>
        </td>
      );
    }

    if (itemKey === "batch") {
      return (
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test.batch ? (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color={"green"} tickIcon />
            </div>
          ) : (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color={"maroon"} crossIcon />
            </div>
          )}
        </td>
      );
    }
    if (itemKey === "releaseStatus") {
      return (
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test.releaseStatus === "Pending" ? (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color={"maroon"} crossIcon />
            </div>
          ) : (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color={"green"} tickIcon />
            </div>
          )}
        </td>
      );
    }

    if (itemKey === "emailSend") {
      return (
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test?.emailSend ? (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color={"green"} tickIcon />
            </div>
          ) : (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color={"maroon"} crossIcon />
            </div>
          )}
        </td>
      );
    }
    if (itemKey === "arkstoneStatus") {
      return (
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test.arkstoneStatus === "Processed" ? (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color={"green"} title={test.arkstoneStatus} tickIcon />
            </div>
          ) : test.arkstoneStatus === "Pending" ? (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" title={test.arkstoneStatus} color={"maroon"} crossIcon />
            </div>
          ) : (
            <></>
          )}
        </td>
      );
    }
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      default:
        break;
    }
  };

  const handleReleaseTest = async () => {
    if (checkboxes.length === 0) return;

    try {
      dispatch(setLoader(true));
      const selectedTests = filteredTests.filter((test) => checkboxes.includes(test.id));

      const testsToReceive = selectedTests.map((t) => ({
        id: t.id,
        barcode: t.barcode,
        userID: appContext?.user?.sub,
        userName: appContext?.user?.name,
      }));

      const fileName = `${moment().format("DD_MM_YYYY_HH_mm_ss")}_auto_release_${CONFIG.envType}.csv`;

      await API.saveFileSendToLab(fileName, testsToReceive);
      const message = `${testsToReceive.length} Tests has been released sucessfully`;

      dispatch(setLoader(false));
      dispatch(setMessage(message, MESSAGE_MODES.success));
    } catch (ex) {
      console.log("Ex", ex);
      dispatch(setLoader(false));
      dispatch(setMessage("Fail to Received the Tests", MESSAGE_MODES.error));
    }
    setCheckboxes([]);
  };

  const exportPersonalize = useMemo(() => {
    const selectedItems = [...personalize.filter((m) => m.isCheck && m.itemKey !== "details").map((m) => m.itemKey)];

    // Find the index of "Test Date" in personalize
    const testDateIndex = personalize.findIndex((m) => m.title === "Test Date");
    // If "Test Date" is checked, insert "testDate" at the correct index
    if (testDateIndex !== -1 && personalize[testDateIndex].isCheck) {
      selectedItems.splice(testDateIndex, 0, "testDate");
    }
    return selectedItems;
  }, [personalize]);

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : (
    <>
      <Container fluid>
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      {/* Date Range */}
                      <FilterSelect setTimeFilter={setTimeFilter} />
                      <Icon
                        handleClick={async () => {
                          if (filteredTests && filteredTests.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                          //  await API.invoicesPDF();
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />

                      <Icon
                        handleClick={() => setOpenPersonalizationModal(true)}
                        title={"Edit Column"}
                        label={"Edit Column"}
                        iconType={"personalizeIcon"}
                      />

                      {isAllowToArchive(appContext.user?.phone_number) && permission?.test?.archiveTest && (
                        <Icon
                          handleClick={() =>
                            appContext.openDeleteConfirmation(
                              filteredTests.filter((f) => checkboxes.includes(f.id)),
                              afterDeletedTest
                            )
                          }
                          title={"Archive"}
                          label={"Archive"}
                          iconType={"binIcon"}
                          disabled={checkboxes.length === 0}
                        />
                      )}
                      <ExportToExcelData
                        items={filteredTests}
                        selectedItem={exportPersonalize}
                        appContext={appContext}
                        filter={{ ...filter, status: "Processed" }}
                        timeFilter={timeFilter}
                        title="Completed Tests"
                        sortBy={sortBy}
                        setLoading={setLoading}
                        isCompletedTests
                      />

                      {permission?.test?.editTest === true && (
                        <Icon
                          handleClick={handleShowResultModal}
                          title={"Edit Results"}
                          label={"Edit Results"}
                          iconType={"resultsIcon"}
                          disabled={checkboxes.length !== 1}
                        />
                      )}

                      {CONFIG.isWhiteLabel && (
                        <>
                          <Icon
                            handleClick={handleExportCalrediePatient}
                            title={"Export to Calredie"}
                            label="Export to H&S"
                            iconType={"XLSIcon"}
                          />
                          <Icon
                            handleClick={handleUploadLogs}
                            title={"H&S Logs"}
                            label="H&S Logs"
                            iconType={"importIcon"}
                          />
                        </>
                      )}
                      {ADMIN_USER.includes(appContext.user?.phone_number) && (
                        <>
                          <Icon
                            handleClick={handleExportLabGen}
                            title={"Send to Lab"}
                            label={"Send to Lab"}
                            iconType={"resultsIcon"}
                            disabled={checkboxes.length === 0}
                          />

                          {/* <Icon
                            handleClick={handleExportBilling}
                            title={"Ex. Billing CSV"}
                            label={"Ex. Billing CSV"}
                            iconType={"resultsIcon"}
                            disabled={filteredTests.length === 0}
                          /> */}
                        </>
                      )}

                      {(appContext.user["custom:role"] === "Admins" || CONFIG.isWhiteLabel) &&
                        permission?.test?.finalizeSendResult === true && (
                          <Icon
                            handleClick={handleSendEmail}
                            disabled={checkboxes.length ? false : true}
                            title={"Send Results"}
                            label={"Send Results"}
                            iconType={"messageIcon"}
                          />
                        )}

                      {filteredTests.length > 0 && !CONFIG.isWhiteLabel && (
                        <>
                          {Object.keys(filter).length > 0 && (
                            <>
                              <TestPDFLayout
                                tests={filteredTests}
                                selectedItem={selectedItem}
                                filter={filter}
                                timeFilter={timeFilter}
                                title="Completed Tests"
                                appContext={appContext}
                              />
                            </>
                          )}
                        </>
                      )}

                      {appContext.user.phone_number == "+12222222222" && (
                        <Icon
                          handleClick={() => setOpenConfirmation(true)}
                          disabled={checkboxes.length ? false : true}
                          title={`${UN_RESULT} Test`}
                          label={`${UN_RESULT} Test`}
                          iconType={"resultsIcon"}
                        />
                      )}
                      <Icon
                        handleClick={() => updateAcknowledgeBy()}
                        title={"Verified Positive"}
                        disabled={
                          filteredTests.filter(
                            (c) => checkboxes.includes(c.id) && c.result === "Positive" && !c.isReview
                          ).length === 0
                        }
                        label="Ver Pos"
                        iconType={"acknowledgedPositiveIcon"}
                      />
                      <Icon
                        handleClick={() => downloadRequisitionFile()}
                        title={"Req Forms"}
                        label="Req Forms"
                        iconType={"dw_Icon"}
                      />
                      {permission?.test?.finalizeSendResult === true && (
                        <Icon
                          handleClick={handleReleaseTest}
                          disabled={checkboxes.length === 0}
                          title={"Release Result"}
                          label={"Release Result"}
                          iconType={"importIcon"}
                        />
                      )}

                      {isLocalhost() && (
                        <Icon
                          handleClick={async () => {
                            await API.resendToARKStone(checkboxes[0]);
                          }}
                          disabled={checkboxes.length !== 1}
                          title={"Resend ARK"}
                          label={"Resend ARK"}
                          iconType={"importIcon"}
                        />
                      )}
                    </div>
                  </div>
                  {showFilter && (
                    <Filter
                      filterTerms={searchTerms}
                      setFilter={setFilter}
                      filter={filter}
                      triggerFilter={triggerFilter}
                      setTriggerFilter={setTriggerFilter}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="6">
                      <Card.Title>
                        <div className="">
                          Completed Tests:
                          <span className="ps-2"> {totalRecord}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <PGPagination
                        timeFilter={timeFilter}
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        totalRecord={totalRecord}
                        currentRecord={filteredTests.length}
                        showSelectedRecord
                        totalSelected={checkboxes.length}
                      />
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                  <MainTable
                    columns={personalize}
                    rows={testsToMap}
                    draggable
                    resizable
                    cssClass="table-noresponsive"
                    trDataWrapperHeader={"trDataWrapperHeader"}
                    personalisationKey={"completedtest"}
                    handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                    widthToSkip={155}
                    customColumnCellRenderer={customRenderTD}
                    selectedRows={checkboxes}
                    tools={[TABLE_QUICK_TOOLS.checkbox]}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    handleCellClick={handleCellClick}
                    dropDownOptions={[
                      { value: "Logs", title: "Audit Trail" },
                      { value: "View", title: "View" },
                    ]}
                    handleDropDownClick={(type, row) => {
                      if (type === "Audit Trail") {
                        appContext.showLogs({ ...row });
                      }
                      if (type === "View") {
                        handleSaveFilter();
                        history.push({
                          pathname: "/admin/test/" + row.id,
                          state: { admin: true, type: "allTest", test: row },
                        });
                      }
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>

      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete User"
          message={`Are you sure, you want to remove Test of ${itemToDelete.name}?`}
          handleConfirm={handleDeleteConfirm}
        />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={personalize}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}

      {showResultModal && (
        <ChangeResultModal
          handleClose={() => setShowResultModal(false)}
          appContext={appContext}
          tests={filteredTests.filter((test) => checkboxes.includes(test.id))}
          callback={clearSelection}
          processed={true}
        />
      )}

      {openUploadLogsModal && (
        <CalRedieLogsModal
          handleClose={() => setOpenUploadLogsModal(false)}
          user={appContext.user}
          appContext={appContext}
        />
      )}
      {openConfirmation && (
        <ConfirmationModal
          show={openConfirmation}
          title={`${UN_RESULT} Tests`}
          message={`Are you sure, you want to ${UN_RESULT} the Tests`}
          handleConfirm={handleConfirmation}
        />
      )}
      <DocumentPreviewModal
        isModalOpen={isPreviewModalOpen}
        setIsModalOpen={setIsPreviewModalOpen}
        fileUrl={fileUrl}
        fileType={fileType}
        title={`Test Results - ${docName ? docName : ""}`}
      />
      {loading && <Loader />}
    </>
  );
};

export default TestListAdminApi;
