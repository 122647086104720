import { useDispatch } from "react-redux";
import { getResultColors } from "../../../utils";
import React, { useContext } from "react";
import { showResultPdf } from "store/features/resultPdf/reslutPdfSlice";
import { AppContext } from "context/app-context";

const ResultCell = ({ test }) => {
  const appContext = useContext(AppContext);

  const parsedResult = appContext.parseResult(test);

  const resultColor = test?.resultColor || getResultColors(test);

  const dispatch = useDispatch();

  const displayPdf = () => {
    
    if (test.status === "Cancel") return;
    dispatch(showResultPdf(test));
  };

  return (
    <td
      className="ellipsis"
      style={{ textAlign: "center" }}
      title={test.status === "Cancel" ? test.cancelReason : parsedResult}
    >
      <span
        className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
        onClick={() => displayPdf()}
        style={{
          color: resultColor,
        }}
      >
        {parsedResult}
      </span>
    </td>
  );
};

export default ResultCell;
