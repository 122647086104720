import api from "api";
import EditIcon from "assets/img/edit-icon.png";
// import TrashIcon from "assets/img/trash-icon.png";
import binIcon from "assets/img/Delete-Icon.svg";

import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import Notes from "components/Notes/Notes";
import Status from "components/Status/Status";
import { currencyformatter, TABLE_COLUMN_TYPES, TABLE_QUICK_TOOLS } from "constant";
import useResizeObserver from "constants/hooks/useResizeObserver";
import { AppContext } from "context/app-context";
import { debounce } from "debounce";
import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  calculateTdWidth,
  capitalizeLetter,
  formattedString,
  convertToUpper,
  personalisationInLC,
  formatDate,
  formatDateMDY,
  tdEmail,
  tdPhone,
  tdResult,
  eligibilityMessageColor,
  eligibilityMessage,
  formatNumber,
  toTitleCase,
  formatLogDate,
  getResultToShow,
  detectChangeInPersonalization,
  getFileNameFromDocuments,
  nameToTitleCase,
  capitalizeBCBSOrTitleCase,
  formatLabelID,
} from "utils";
import HeaderItem from "./HeaderItem";
import { parseTestUnit } from "utils";
import ResultCell from "views/GraphQL/TestListCompletedTest/ResultCell";

const MainTable = (props) => {
  const {
    columns,
    rows,
    individualRowCssClass,
    handleSaveDragAndResize,
    personalisationKey,
    draggable,
    resizable,
    flipSort,
    sortBy,
    sortDescending,
    widthToSkip,
    tools = [],
    dropDownOptions,
    customDropDownOptsForRow,
    handleDropDownClick,
    selectedRows = [],
    handleCellClick = null,
    cssClass,
    customColumnCellRenderer,
    parentKey,
    trDataWrapperHeader = "",
    isTestDoneModal,
    strippedTable,
    headerStyle,
  } = props;

  const [columnToBeSwapped, setColumnToBeSwapped] = useState(null);
  const [reset, setReset] = useState(false);
  const [width, setWidth] = useState(0);
  const resizeableTable = useRef();
  const tableWrapperRef = useRef();
  const appContext = useContext(AppContext);

  //Screen Resolution Change
  useEffect(() => {
    handleScreenWidthChange();
  }, [tableWrapperRef]);

  useEffect(() => {
    window.addEventListener("resize", handleScreenWidthChange);
    return () => {
      window.removeEventListener("resize", handleScreenWidthChange);
    };
  });

  const handleScreenWidthChange = () => {
    if (tableWrapperRef.current) setWidth(tableWrapperRef.current.offsetWidth);
  };

  const tdWidth = calculateTdWidth(
    width - (widthToSkip || 0),
    columns.length > 0 ? columns.filter((p) => p.isCheck).length : 2
  );

  const handlePersonalization = async (column, data) => {
    if (!personalisationKey) return;

    const isChanged = detectChangeInPersonalization(column, data);

    if (isChanged) {
      handleSaveDragAndResize([...data]);

      const personalisationData = personalisationInLC.saveAs(data, personalisationKey);

      await api.saveUserPersonalisation(appContext.user.sub, personalisationData);
    }
  };

  useEffect(() => {
    if (reset) {
      setTimeout(() => setReset(false), 100);
    }
  }, [reset]);

  // Column Resizing
  let dimensions = null;

  if (resizable) dimensions = useResizeObserver(resizeableTable);

  useEffect(() => {
    handleScreenWidthChange();
    if (dimensions) {
      const props = { columns, whiteSpaceVisible: width - dimensions.width };
      handleColumnResizerDelay(props);
    }
  }, [dimensions]);

  const handleColumnResizerDelay = useCallback(
    debounce((props) => {
      handleColumnResizer(props);
    }, 200),
    []
  );

  const handleColumnResizer = (props) => {
    const { columns, whiteSpaceVisible } = props;
    if (!resizeableTable || !resizeableTable.current?.childNodes?.length > 0) return;
    if (!columns || columns.length === 0) return;

    const headerCells = resizeableTable.current?.childNodes[0]?.childNodes[0]?.childNodes;
    if (!headerCells || !headerCells?.length > 2) return;

    let eachColumnAdditionalWidth = 0;
    if (Math.floor(whiteSpaceVisible) > 4) {
      eachColumnAdditionalWidth = Math.floor(whiteSpaceVisible / (headerCells.length - 2));
    }
    let personalizeToSave = [...columns];
    // console.log("ColumnsINSIDE", columns);
    for (let i = 1; i < headerCells.length - 1; i++) {
      const colIndex = personalizeToSave.findIndex(
        (column) => column.title?.toLowerCase() === headerCells[i]?.childNodes[0]?.innerText?.toLowerCase().trim()
      );

      if (colIndex === -1) continue;

      const obj = {
        ...personalizeToSave[colIndex],
        width: headerCells[i].childNodes[0].offsetWidth + eachColumnAdditionalWidth,
      };
      personalizeToSave.splice(colIndex, 1, { ...obj });
    }
    setReset(true);

    handlePersonalization(columns, personalizeToSave);
  };

  // Draggable Column
  const handleDragStart = (e) => {
    const targetObj =
      columns.find((col) => col.isCheck && col.title.toLowerCase() === e.target.innerText.toLowerCase()) || null;
    setColumnToBeSwapped(targetObj);
  };

  const handleColumnChange = (e) => {
    const targetDestinationObj = columns.find(
      (col) => col.isCheck && col.title.toLowerCase() === e.target.innerText.toLowerCase()
    );

    if (!columnToBeSwapped || !targetDestinationObj) return;
    if (columnToBeSwapped.id === targetDestinationObj.id) return;

    let personalisationData = [...columns];
    const subjectIndex = personalisationData.findIndex((col) => col.id === columnToBeSwapped.id);
    const targetIndex = personalisationData.findIndex((col) => col.id === targetDestinationObj.id);

    const temp = personalisationData.splice(subjectIndex, 1)[0];
    personalisationData.splice(targetIndex, 0, temp);
    handlePersonalization(columns, personalisationData);
  };

  const handleClickCall = (item, row) => {
    handleCellClick && handleCellClick(item.itemKey, row);
  };

  const headerAlign = (item) => (item === "center" ? "centered" : item === "right" ? "right" : undefined);

  const tdFormat = (item, row, title, rowIndex) => {
    if (item.itemKey === "eligibilityStatus") {
      const eligMessage = eligibilityMessage(row[item.itemKey], row);
      if (title) {
        return eligMessage;
      }

      return (
        <div className="star-icon-wrapper cursor-pointer">
          <Status
            type="circle"
            size="md"
            color={eligibilityMessageColor(eligMessage)}
            crossIcon="Yes"
            title={eligMessage}
          />
        </div>
      );
    }

    if (item.itemKey === "status") {
      if (title) return row[item.itemKey] === "active" ? "Active" : "InActive";
      return (
        <div className="star-icon-wrapper cursor-pointer">
          <Status
            type="circle"
            size="md"
            color={row[item.itemKey] === "active" ? "green" : "maroon"}
            crossIcon={row[item.itemKey]}
          />
        </div>
      );
    }
    if (item.itemKey === "serialNo") {
      return <div># {rowIndex + 1}</div>;
    }

    if (item.itemKey === "firstName" || item.itemKey === "lastName") {
      return nameToTitleCase(row[item.itemKey] || "");
    }

    //  for payer
    // if (item.itemKey === "Name") {
    //   return capitalizeBCBSOrTitleCase(row[item.itemKey] || "");
    // }

    if (item.itemKey === "fileName") {
      return getFileNameFromDocuments(row[item.itemKey]);
    }
    const rowItemData = row[item.itemKey];

    if (
      (!rowItemData || rowItemData === "undefined") &&
      item.type !== TABLE_COLUMN_TYPES.currency &&
      item.type !== TABLE_COLUMN_TYPES.number
    ) {
      return "";
    }

    switch (item.type) {
      case TABLE_COLUMN_TYPES.shortDate:
        return formatDateMDY(row[item.itemKey]);

      case TABLE_COLUMN_TYPES.longDate:
        return formatDateMDY(row[item.itemKey]);

      case TABLE_COLUMN_TYPES.dateTime:
        return formatLogDate(row[item.itemKey]);

      case TABLE_COLUMN_TYPES.currency:
        return currencyformatter.format(row[item.itemKey] || 0);
      case TABLE_COLUMN_TYPES.number:
        return formatNumber(row[item.itemKey] || 0);
      case TABLE_COLUMN_TYPES.upperCaseText:
        return convertToUpper(row[item.itemKey] || "");
      case TABLE_COLUMN_TYPES.firstLetterCapital:
        return formattedString(row[item.itemKey] || "");
      case TABLE_COLUMN_TYPES.labelID:
        return formatLabelID(row[item.itemKey]);
      default:
        // return row[item.itemKey] ? row[item.itemKey] : "";
        return row?.[item.itemKey] ?? "";
    }
  };

  const renderTd = (item, row, index, rowIndex) => {
    if (item.customCellRenderer) return customColumnCellRenderer(item, row, index, rowIndex);
    switch (item.type) {
      case TABLE_COLUMN_TYPES.fixedValue:
        return (
          <td
            key={`fixed_item_${index}`}
            // key={item.id}
            className={`ellipsis ${item.cssClass ?? ""}`}
            style={{
              textAlign: item.textAlign,
              textOverflow: item.textOverflow,
            }}
            onClick={() => handleClickCall(item, row)}
          >
            {item.text}
          </td>
        );

      case TABLE_COLUMN_TYPES.json:
        return <td>{row[item.itemKey][0].value}</td>;
      case TABLE_COLUMN_TYPES.email:
        return tdEmail(row[item.itemKey]);

      case TABLE_COLUMN_TYPES.phone:
        return tdPhone(row[item.itemKey]);

      case TABLE_COLUMN_TYPES.result:
        return <ResultCell test={row} />;
      // const resultData = getResultToShow(row);
      // return tdResult({
      //   index,
      //   result: resultData,
      //   handleCellClick: () => handleClickCall(item, row),
      //   appContext,
      //   row,
      // });

      default:
        return (
          <td
            // key={item.id}
            key={`default_item_${index}`}
            className={`ellipsis ${item.cssClass ?? ""}`}
            style={{
              textAlign: item.textAlign,
              textOverflow: item.textOverflow,
              color: `${item.colorObj ? item.colorObj[row[item.itemKey]] : ""}`,
            }}
            onClick={() => handleClickCall(item, row)}
            title={tdFormat(item, row, "title")}
          >
            {tdFormat(item, row, "", rowIndex)}
          </td>
        );
    }
  };
  const isQuickToolIncluded = (tools) => {
    return [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.notes].some((tool) =>
      tools.includes(TABLE_QUICK_TOOLS[tool])
    );
  };
  function renderTableHeader() {
    return (
      tools.length > 0 &&
      isQuickToolIncluded(tools) && (
        <th className={`tools-td ${tools.length > 0 ? "tools-available" : "tools-unavailable"}`} />
      )
    );
  }

  const TableRow = ({ row, index }) => {
    if (row.antibioticDivider) {
      return (
        <tr
          key={`${row?.id}_row_${index}`}
          className={`${individualRowCssClass ? individualRowCssClass(row) : "trDataWrapper"}`}
        >
          <td colSpan={columns.length + 1} className="text-center " style={{ fontWeight: "bold" }}>
            {row.label}
          </td>
        </tr>
      );
    }

    return (
      <tr
        key={`${row?.id}_row_${index}`}
        className={`${individualRowCssClass ? individualRowCssClass(row) : "trDataWrapper"}`}
      >
        {tools.length > 0 && isQuickToolIncluded(tools) && (
          <td
            className={`ellipsis tools-td ${tools.length > 0 ? "tools-available" : "tools-unavailable"}`}
            style={{
              minWidth: `${!tools.includes(TABLE_QUICK_TOOLS.delete) && tools.length && tools.length * 33.33}px`,
            }}
            // style={{ minWidth: `${    tools .length * 33.33}px` }}
          >
            <div className="d-flex align-items-center w-100 column-gap-10">
              {tools.includes(TABLE_QUICK_TOOLS.checkbox) && (
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    checked={selectedRows.indexOf(row?.id) !== -1}
                    value={row?.id}
                    onChange={(event) =>
                      handleCellClick && handleCellClick(TABLE_QUICK_TOOLS.checkbox, row, event, index)
                    }
                  />
                </Form.Check>
              )}
              {tools.includes(TABLE_QUICK_TOOLS.edit) && (
                <img
                  src={EditIcon}
                  alt="edit icon"
                  width="18"
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  aria-hidden="true"
                  id={row?.id}
                  onClick={(event) => handleCellClick && handleCellClick(TABLE_QUICK_TOOLS.edit, row, event)}
                />
              )}
              {tools.includes(TABLE_QUICK_TOOLS.notes) && (
                <Notes
                  note={
                    typeof row?.note === "object"
                      ? row?.note?.message
                      : row?.note || row?.notes || row?.employee_demographics?.comment
                  }
                  empNotes={typeof row?.note === "object" ? row?.note?.message : row?.note}
                  handleClick={() => handleCellClick && handleCellClick(TABLE_QUICK_TOOLS.notes, row)}
                />
              )}
            </div>
          </td>
        )}

        {columns.map((col, colIndex) => col.isCheck && renderTd(col, row, colIndex, index))}

        {(tools.includes(TABLE_QUICK_TOOLS.delete) ||
          (customDropDownOptsForRow && customDropDownOptsForRow.length > 0) ||
          (dropDownOptions && dropDownOptions.length > 0)) && (
          <td
            className={`icon tools-td text-center ${rows.length === 1 ? "single-row-dropdown-menu" : ""} ${
              tools.includes(TABLE_QUICK_TOOLS.delete) || customDropDownOptsForRow
                ? "tools-available"
                : "tools-unavailable"
            }`}
          >
            {((customDropDownOptsForRow && customDropDownOptsForRow.length > 0) ||
              (dropDownOptions && dropDownOptions.length > 0)) && (
              <DropdownMenu
                options={customDropDownOptsForRow ? customDropDownOptsForRow(row) : dropDownOptions}
                handleDropDownOptions={(type) => handleDropDownClick(type, row)}
              />
            )}

            {tools.includes(TABLE_QUICK_TOOLS.delete) && (
              <img
                src={binIcon}
                alt="trash icon"
                width="15"
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                id={row?.id}
                onClick={(event) => handleCellClick && handleCellClick(TABLE_QUICK_TOOLS.delete, row, event, index)}
              />
            )}
          </td>
        )}

        {/* <td
          className={`icon tools-td text-center ${rows.length === 1 ? "single-row-dropdown-menu" : ""} ${
            tools.includes(TABLE_QUICK_TOOLS.delete) || customDropDownOptsForRow
              ? "tools-available"
              : "tools-unavailable"
          }`}
        >
          {((customDropDownOptsForRow && customDropDownOptsForRow.length > 0) ||
            (dropDownOptions && dropDownOptions.length > 0)) && (
            <DropdownMenu
              options={customDropDownOptsForRow ? customDropDownOptsForRow(row) : dropDownOptions}
              handleDropDownOptions={(type) => handleDropDownClick(type, row)}
            />
          )}

          {tools.includes(TABLE_QUICK_TOOLS.delete) && (
            <img
              src={binIcon}
              alt="trash icon"
              width="15"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={row?.id}
              onClick={(event) => handleCellClick && handleCellClick(TABLE_QUICK_TOOLS.delete, row, event, index)}
            />
          )}
        </td> */}
      </tr>
    );
  };

  const renderDetails = () => {
    if (
      (customDropDownOptsForRow && customDropDownOptsForRow.length > 0) ||
      (dropDownOptions && dropDownOptions.length > 0)
    ) {
      return (
        <th className={`tools-td text-center ${tools.length > 0 ? "tools-available" : "tools-unavailable"}`}>
          {isTestDoneModal ? "Action" : "Details"}
        </th>
      );
    }
    if (tools.includes(TABLE_QUICK_TOOLS.delete)) {
      return <th className={`tools-td text-center ${tools.length > 0 ? "tools-available" : "tools-unavailable"}`}></th>;
    }
  };

  return (
    <>
      <div
        ref={tableWrapperRef}
        style={{ height: rows.length === 1 ? "160px" : "auto" }}
        className={`table-responsive pendingReleaseTable ${resizable ? "empClaimTable" : ""} ${cssClass || ""}`}
      >
        <Table ref={resizeableTable} className={`${strippedTable ? "table-striped" : "separate-border-tr"}`}>
          <thead>
            <tr className={trDataWrapperHeader}>
              {renderTableHeader()}
              {columns.map(
                (item, i) =>
                  item.isCheck && (
                    <HeaderItem
                      key={`${i}_hc`}
                      draggable={draggable}
                      width={!resizable && (item.columnWidth || tdWidth)}
                      // minWidth={resizable && tdWidth}
                      innerDivWidth={resizable && (reset ? `${item.width}px` : item.width)}
                      handleDrop={handleColumnChange}
                      handleDragStart={handleDragStart}
                      ItemKey={item.itemKey}
                      title={item.title}
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned={headerAlign(item.textAlign)}
                      cssClass={resizable ? "claimTableTH" : ""}
                      headerStyle={headerStyle}
                    />
                  )
              )}

              {renderDetails()}
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((row, index) => {
                return <TableRow key={`${index}_row`} row={row} index={index} />;
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default MainTable;
