import React from "react";
import "./TestDetailsRangeView.css";
import Icon from "components/Icon";
import { Button, Modal, Form } from "react-bootstrap";
import Select from "react-select";
import { GENDER_OPTIONS } from "constant";
import RangeBacteriaSelector from "./RangeBacteriaSelector";

const TestDetailsRangeView = ({ onChange, data, handelRemoveView, handleClose, handleAddRange, onChangeGender,onChangeBacteria }) => {
  const rangesArr = ["Low", "Normal", "High", "Panic", "Repeat"];

  return (
    <Modal
      show
      animation={true}
      className="second-modal modal-padding-0"
      onHide={() => handleClose()}
      centered
      size={"full"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Test Ranges
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div style={{ border: "1px solid gray", padding: "10px", marginTop: "10px", borderRadius: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 style={{ margin: 0 }}>Range Setup</h4>

            <Icon
              iconType={"createIcon"}
              containerClass={"ms-2 cursor-pointer"}
              innerClass={""}
              handleClick={handleAddRange}
            />
          </div>

          <div className="range-view-container">
            <table className="range-view-table">
              <thead>
                <tr>
                  <th className="range-view-th range-special-bg" colSpan="2">
                    Name
                  </th>
                  <th className="range-view-th range-special-bg" colSpan="2">
                    Gender
                  </th>
                  <th className="range-view-th range-special-bg" colSpan="2">
                    Age Range
                  </th>

                  {rangesArr.map((range, index) => (
                    <th className="range-view-th" colSpan="2" key={`range_${index}`}>
                      {range}
                    </th>
                  ))}
                  <th className="range-view-th">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="range-view-td text-center range-special-bg" colSpan="2"></td>
                  <td className="range-view-td text-center range-special-bg" colSpan="2"></td>
                  <td className="range-view-td text-center range-special-bg">Start</td>
                  <td className="range-view-td text-center range-special-bg">End</td>

                  {rangesArr.map((item, index) => (
                    <>
                      <td className="range-view-td text-center" key={`low_${index}`}>
                        Low
                      </td>
                      <td className="range-view-td text-center" key={`high_${index}`}>
                        High
                      </td>
                    </>
                  ))}

                  <td style={{ border: "none" }}></td>
                </tr>

                {data.map(
                  (
                    {
                      id,
                      Gender,
                      AgeStart,
                      AgeEnd,

                      NormalLow,
                      NormalHigh,

                      HighLow,
                      HighHigh,

                      PanicLow,
                      PanicHigh,

                      RepeatLow,
                      RepeatHigh,

                      LowLow,
                      LowHigh,
                    },
                    index
                  ) => {
                    return (
                      <tr key={index}>
                        <td colSpan="2" style={{ minWidth: "150px" }} className="text-center range-special-bg">
                          {/* <Select
                            className="w-100 siteSelector"
                            options={GENDER_OPTIONS}
                            blurInputOnSelect={true}
                            defaultValue={null}
                            menuPlacement="auto"
                            value={Gender}
                            name="Gender"
                            placeholder="Gender"
                            onChange={(e) => onChangeGender(e, index)}
                          /> */}
                          <RangeBacteriaSelector 

                          onChange={(e) => onChangeBacteria(e, index)}
                          
                          />
                        </td>

                        <td colSpan="2" style={{ minWidth: "150px" }} className="text-center range-special-bg">
                          <Select
                            className="w-100 siteSelector"
                            options={GENDER_OPTIONS}
                            blurInputOnSelect={true}
                            defaultValue={null}
                            menuPlacement="auto"
                            value={Gender}
                            name="Gender"
                            placeholder="Gender"
                            onChange={(e) => onChangeGender(e, index)}
                          />
                        </td>
                        <td className="text-center range-special-bg">
                          <input
                            className="range-view-input"
                            name="AgeStart"
                            value={AgeStart}
                            onChange={(e) => onChange(e, index)}
                            type="number"
                          />
                        </td>
                        <td className="text-center range-special-bg">
                          <input
                            className="range-view-input"
                            name="AgeEnd"
                            value={AgeEnd}
                            onChange={(e) => onChange(e, index)}
                            type="number"
                          />
                        </td>

                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="LowLow"
                            value={LowLow}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="LowHigh"
                            value={LowHigh}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>

                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="NormalLow"
                            value={NormalLow}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="NormalHigh"
                            value={NormalHigh}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>

                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="HighLow"
                            value={HighLow}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="HighHigh"
                            value={HighHigh}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>

                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="PanicLow"
                            value={PanicLow}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="PanicHigh"
                            value={PanicHigh}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>

                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="RepeatLow"
                            value={RepeatLow}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            className="range-view-input"
                            name="RepeatHigh"
                            value={RepeatHigh}
                            onChange={(e) => onChange(e, index)}
                          />
                        </td>

                        <td className="text-center">
                          <Icon
                            iconType={"binIcon"}
                            containerClass={"cursor-pointer"}
                            innerClass={""}
                            imageClass={"delete-icon "}
                            handleClick={() => handelRemoveView(id)}
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={() => handleClose()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default TestDetailsRangeView;
