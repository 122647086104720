import { CPT_CODE_SEARCH_TYPE } from "constant";
import React, { useState, memo, useCallback, useRef, useMemo } from "react";
import { customCloneDeep } from "util/customLodash";
import { Accordion } from "react-bootstrap";
import MainTable from "components/Table/MainTable";
import { ORDER_DIAG_PERSONALIZATION } from "constants/personalization";
import { TABLE_QUICK_TOOLS } from "constant";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import {
  countTotalCpt,
  makeUniqueSources,
  formatCurrency,
  parseCurrency,
  ictAlreadyExist,
  isAnySourceExist,
} from "utils";
import { ReactSelectCustomContainer } from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
import Select from "react-select";

import OrderTestSelector from "./OrderTestSelector";

const OrderDiagnosisView = ({
  orderIcdData,
  isSiteUser,
  setOrderIcdData,
  appContext,
  showSourceSiteModal,
  labClientTest,
}) => {
  const newEmptyTestData = {
    test: "",
    cpt: "",
    source: "",
    unit: "",
    refCharge: "",
    ict: [],
  };

  const testIds = useMemo(() => {
    return orderIcdData?.[0]?.orderData.map((item) => item.test.id).filter(Boolean);
  }, [orderIcdData]);

  const getDiagOptions = useMemo(() => {
    return orderIcdData
      .filter((item) => item.description)
      .map((item) => ({
        label: item.title,
        value: item?.description?.code || "",
      }));
  }, [orderIcdData]);

  const [activeKey, setActiveKey] = useState(() => Array.from({ length: 8 }, (_, i) => Number(i + 1)));

  const handleDiagCode = useCallback(
    (cpt, id, title) => {
      console.log("CPT", cpt);
      console.log("ID", id);
      console.log("Title", title);
      if (cpt) {
        const isAlreadyExist = ictAlreadyExist(orderIcdData, cpt?.code);

        if (!isAlreadyExist) {
          setOrderIcdData((prevData) => {
            const cloneOrderIcd = customCloneDeep(prevData);
            cloneOrderIcd[id].description = { ...cpt, title };

            // if (cloneOrderIcd[0].orderData[cloneOrderIcd[0].orderData.length - 1].test !== "") {
            //   cloneOrderIcd[id].orderData.push(newEmptyTestData);
            // }

            return cloneOrderIcd;
          });
        } else {
          appContext.showErrorMessage("This ict already used.");
        }
      }
      else{
        setOrderIcdData((prevData) => {
          const cloneOrderIcd = customCloneDeep(prevData);
          cloneOrderIcd[id].description = null

          return cloneOrderIcd;
        });
      }
    },
    [orderIcdData]
  );

  const handleToggle = useCallback(
    (newCode) => {
      setActiveKey((currentCodes) => {
        const found = activeKey.includes(newCode);
        return found ? currentCodes.filter((code) => code !== newCode) : [...currentCodes, newCode];
      });
    },
    [activeKey]
  );

  const getFromatedCurrency = (chargeValue) => {
    const parsedValue = parseCurrency(chargeValue);
    const formatedValue = formatCurrency(parsedValue);
    return formatedValue;
  };

  const handleGetTests = useCallback(
    (test, tableId, rowIndex) => {
      let testName = test.name;

      let orderData = orderIcdData[0].orderData;

      const isAlreadyExist = testName ? orderData.some((item) => item.test.name === testName) : false;

      if (isAlreadyExist) {
        appContext.showErrorMessage("This test already used.");
        return;
      }
      const sourcesList = makeUniqueSources(test.sources);

      const isAnySrcExist = isAnySourceExist({ orderArray: orderData, sourcesArray: sourcesList });

      const isTestCreated = orderData.some((item) => item?.test?.barcode);

      if (isTestCreated && isSiteUser && !isAnySrcExist) {
        appContext.showErrorMessage("This test source not exist in above tests.");
        return;
      }

      const hasCptCodes = test?.cptCodes?.length > 0;

      let formattedTests = { ...test, sources: sourcesList };

      if (isTestCreated && isSiteUser) {
        const srcList = orderData
          .flatMap((item) => item.source)
          .filter(Boolean)
          .reduce((acc, current) => {
            const exists = acc.some((obj) => obj.value === current.value);
            if (!exists) {
              acc.push(current);
            }
            return acc;
          }, []);
        formattedTests.sources = srcList;
      }

      let defaultICT = getDiagOptions.length === 1 ? getDiagOptions.splice(0, 1) : null;

      const newData = {
        ...newEmptyTestData,
        test: formattedTests,
        unit: 1,
        cpt: hasCptCodes ? test?.cptCodes?.[0] : {},
        refCharge: getFromatedCurrency(0),
        source: sourcesList?.length > 0 ? sourcesList[0] : "",
        ...(defaultICT && { ict: defaultICT }),
      };

      const cloneOrderIcd = customCloneDeep(orderIcdData);

      cloneOrderIcd[tableId].orderData[rowIndex] = newData;

      // cloneOrderIcd[tableId].orderData.pop();
      // cloneOrderIcd[tableId].orderData.push(newData);

      // if(!testName){
      //   cloneOrderIcd[tableId].orderData[rowIndex] = newEmptyTestData
      // }

      setOrderIcdData(cloneOrderIcd);
    },
    [orderIcdData]
  );

  const handleSetCpt = useCallback((cpt, tableId, rowIndex) => {
    if (cpt) {
      setOrderIcdData((prevData) => {
        const cloneOrderIcd = customCloneDeep(prevData);
        cloneOrderIcd[tableId].orderData[rowIndex].cpt = cpt;
        cloneOrderIcd[tableId].orderData[rowIndex].refCharge = getFromatedCurrency(cpt?.referenceCharge || 0);
        return cloneOrderIcd;
      });
    }
  }, []);

  const handleSetUnit = useCallback((event, tableId, rowIndex) => {
    let unitValue = event.target.value;
    ``;

    if (unitValue.startsWith(".") && unitValue.length > 1) {
      unitValue = `0${unitValue}`;
    }

    if (unitValue === "" || !isNaN(unitValue)) {
      setOrderIcdData((prevData) => {
        const cloneOrderIcd = customCloneDeep(prevData);
        cloneOrderIcd[tableId].orderData[rowIndex].unit = unitValue;
        return cloneOrderIcd;
      });
    }
  }, []);

  const handleSetRefCharge = useCallback((event, tableId, rowIndex) => {
    let chargeValue = event.target.value;

    if (chargeValue.startsWith(".") && chargeValue.length > 1) {
      chargeValue = `0${chargeValue}`;
    }

    const parsedValue = parseCurrency(chargeValue);
    const formatedValue = formatCurrency(parsedValue);

    setOrderIcdData((prevData) => {
      const cloneOrderIcd = customCloneDeep(prevData);
      cloneOrderIcd[tableId].orderData[rowIndex].refCharge = formatedValue;
      return cloneOrderIcd;
    });
  }, []);

  const CustomInput = memo(({ defaultValue, onBlur, placeholder }) => {
    const inputRef = useRef(null);

    return (
      <td>
        <input
          className="w-100 modalInput"
          ref={inputRef}
          defaultValue={defaultValue || ""}
          onBlur={onBlur}
          onFocus={() => inputRef.current && inputRef.current.select()}
          placeholder={placeholder || ""}
        />
      </td>
    );
  });

  const handelSetSource = useCallback((value, tableIndex, rowIndex) => {
    if (value) {
      if (value.label === "Wound") {
        showSourceSiteModal(tableIndex, rowIndex);
      }

      setOrderIcdData((prevData) => {
        const currentSource = prevData[tableIndex]?.orderData[rowIndex]?.source;

        const cloneOrderIcd = customCloneDeep(prevData);
        cloneOrderIcd[tableIndex].orderData[rowIndex].source = value;

        return cloneOrderIcd;
      });
    }
  }, []);

  const handelSetDiagnosis = useCallback((value, tableIndex, rowIndex) => {
    if (value) {
      setOrderIcdData((prevData) => {
        const currentSource = prevData[tableIndex]?.orderData[rowIndex]?.ict;

        const cloneOrderIcd = customCloneDeep(prevData);
        cloneOrderIcd[tableIndex].orderData[rowIndex].ict = value;

        return cloneOrderIcd;
      });
    }
  }, []);

  const customRenderTD = (item, row, cellIndex, rowIndex, tableIndex) => {
    const { itemKey, id } = item;
    if (itemKey === "test") {
      return (
        <td key={`test_${id}_${rowIndex}_${tableIndex}`}>
          <OrderTestSelector
            dataIds={testIds}
            onChange={(test) => handleGetTests(test, tableIndex, rowIndex)}
            value={row?.test || null}
            labClientTest={labClientTest}
          />
        </td>
      );
    }
    if (itemKey === "cpt") {
      return (
        <td key={`cpt${id}_${rowIndex}_${tableIndex}`}>
          <InputCPTCodeDropDown
            type="text"
            inputStyle="modalInput"
            labelStyle=" text-capitalize"
            groupWrapper="mb-0"
            placeholder={"Select CPT"}
            optionList={[]}
            handleChange={(data) => handleSetCpt(data, tableIndex, rowIndex)}
            searchtype={CPT_CODE_SEARCH_TYPE.CPT}
            selectedDiagnosis={[]}
            value={row?.cpt ? row?.cpt?.code : ""}
            showCross={true}
          />
        </td>
      );
    }
    if (itemKey === "source") {
      return (
        <td key={`source_${id}_${tableIndex}_${rowIndex}`}>
          <Select
            options={row?.test?.sources}
            blurInputOnSelect={true}
            menuPlacement="auto"
            className="w-100 siteSelector"
            placeholder="Select Source"
            value={row?.source}
            onChange={(value) => handelSetSource(value, tableIndex, rowIndex)}
            components={{ ValueContainer: ReactSelectCustomContainer }}
          />
        </td>
      );
    }
    if (itemKey === "icd") {
      return (
        <td key={`icd${id}_${tableIndex}_${rowIndex}`}>
          <Select
            options={getDiagOptions || []}
            blurInputOnSelect={true}
            menuPlacement="auto"
            className="w-100 siteSelector"
            placeholder="Select the Linked Code"
            value={row?.ict}
            isMulti
            onChange={(value) => handelSetDiagnosis(value, tableIndex, rowIndex)}
            components={{ ValueContainer: ReactSelectCustomContainer }}
          />
        </td>
      );
    }
    if (itemKey === "unit") {
      return (
        <CustomInput
          onBlur={(value) => handleSetUnit(value, tableIndex, rowIndex)}
          defaultValue={row?.unit || ""}
          placeholder={"Enter unit value"}
        />
      );
    }
    if (itemKey === "refCharge") {
      return (
        <CustomInput
          onBlur={(value) => handleSetRefCharge(value, tableIndex, rowIndex)}
          defaultValue={row?.refCharge || ""}
          placeholder={"Enter reference charge"}
        />
      );
    }
  };

  const handleCellClick = useCallback((key, row, event, rowIndex, tableIndex) => {
    if (key === TABLE_QUICK_TOOLS.delete) {
      const isTestCreated = row?.test?.barcode;
      if (isTestCreated && isSiteUser) {
        appContext.showErrorMessage("You do not have the necessary permissions to delete this test.");
      } else {
        removeTest(key, row, event, rowIndex, tableIndex);
      }
    }
  }, []);

  const removeTest = useCallback((key, row, event, rowIndex, tableIndex) => {
    if (row) {
      setOrderIcdData((prevData) => {
        const cloneOrderIcd = customCloneDeep(prevData);
        if (cloneOrderIcd[tableIndex].orderData.length > 1) {
          cloneOrderIcd[tableIndex].orderData.splice(rowIndex, 1);
        }
        return cloneOrderIcd;
      });
    }
  }, []);

  return (
    <>
      <div className="order-ict-view">
        {orderIcdData.map((item, index) => {
          const { title, id, description } = item;
          return (
            <div className="order-ict-selector" key={id}>
              <InputCPTCodeDropDown
                type="text"
                inputStyle="modalInput"
                labelStyle=" text-capitalize"
                groupWrapper={"mt-1 form-group-wrapper margin-bottom-1"}
                placeholder={"Diag Code"}
                optionList={[]}
                handleChange={(cpt) => handleDiagCode(cpt, index, title)}
                searchtype={CPT_CODE_SEARCH_TYPE.ICT}
                selectedDiagnosis={[]}
                value={description ? description.code : ""}
                showCross={true}
                label={title}
                showInput={true}
                showLabel={true}
                showLabelOnTop={true}
              />
            </div>
          );
        })}
      </div>

      <Accordion activeKey={activeKey} className="mt-2">
        {orderIcdData.map((item, index) => {
          if (index !== 0) return null;
          const { id, description, orderData } = item;
          const totalCpt = countTotalCpt(orderData);
          if (orderData.length) {
            return (
              // <Card className="mb-2 " key={`order_row_${index}`}>
              //   <Card.Header className={`py-2 shadow-sm`} style={{ display: "flex", background: "#eaeaea" }}>
              //     <div style={{ display: "flex", width: "50px", justifyContent: "center", alignItems: "center" }}>
              //       <OrderDiagnosisAccordionButton
              //         eventKey={`${id}`}
              //         isActive={activeKey.includes(id)}
              //         onClick={() => handleToggle(id)}
              //       ></OrderDiagnosisAccordionButton>
              //     </div>

              //     <div className="d-flex w-100 align-items-center" style={{ marginLeft: "20px" }}>
              //       <div className="d-flex mb-0" style={{ flex: "0 0 11%" }}>
              //         <p className="mb-0 font-bold">Code:</p>
              //         <p className="mb-0" style={{ marginLeft: "5px" }}>
              //           {description?.code || ""}
              //         </p>
              //       </div>
              //       <div className="d-flex mb-0" style={{ flex: "0 0 80%" }}>
              //         <p className="mb-0 font-bold">Description:</p>
              //         <p className="mb-0" style={{ marginLeft: "5px" }}>
              //           {description?.title || ""}
              //         </p>
              //       </div>
              //     </div>
              //     <div className="mt-2" style={{ width: "200px", textAlign: "right" }}>
              //       <p className="mb-0 font-bold">Total CPT: {totalCpt}</p>
              //     </div>
              //   </Card.Header>
              //   <Accordion.Collapse eventKey={id}>
              //     <Card.Body>
              //       <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" }}>
              //         <MainTable
              //           cssClass="table-noresponsive"
              //           trDataWrapperHeader={"trDataWrapperHeader"}
              //           customColumnCellRenderer={(prItem, row, cellIndex, rowIndex) =>
              //             customRenderTD(prItem, row, cellIndex, rowIndex, index)
              //           }
              //           columns={ORDER_DIAG_PERSONALIZATION}
              //           rows={orderData}
              //           tools={[TABLE_QUICK_TOOLS.delete]}
              //           handleCellClick={(key, row, event, rowIndex) =>
              //             handleCellClick(key, row, event, rowIndex, index)
              //           }
              //         />
              //       </div>
              //     </Card.Body>
              //   </Accordion.Collapse>
              // </Card>
              <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" }}>
                <MainTable
                  cssClass="table-noresponsive"
                  trDataWrapperHeader={"trDataWrapperHeader"}
                  customColumnCellRenderer={(prItem, row, cellIndex, rowIndex) =>
                    customRenderTD(prItem, row, cellIndex, rowIndex, index)
                  }
                  columns={ORDER_DIAG_PERSONALIZATION}
                  rows={orderData}
                  tools={[TABLE_QUICK_TOOLS.delete]}
                  handleCellClick={(key, row, event, rowIndex) => handleCellClick(key, row, event, rowIndex, index)}
                />
              </div>
            );
          }
        })}
      </Accordion>
    </>
  );
};

export default memo(OrderDiagnosisView);
