import API from "api";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import MFPagination from "components/Pagination/MFPagination";
import { PAGE_LIMIT, newLabClientObject, CLIENT_MANAGEMENT, CONFIG } from "constant";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useState, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { checkValidity, sortingFilterInLC } from "utils";
import { t } from "stringConstants";

import ImportErrorModal from "components/Modal/ImportErrorModal";
import MainTable from "components/Table/MainTable";
import { MESSAGE_MODES, TABLE_QUICK_TOOLS } from "constant";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "store/features/general/generalAction";
import { formatNumber } from "utils";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { fetchAllLocations, createLabClient, updateLabClient } from "store/features/locations/locationsSlice";
import { refLabsSelectors } from "store/features/locations/locationsSelectors";
import { providerList } from "store/features/providers/providersSelectors";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import ReferenceLabModal from "./RefernceLabModal";
import { REFERENCE_PERSONALIZE } from "constants/personalization";
import Icon from "components/Icon";
import { REFERENCE } from "constants/tabPillsContants";
import Filter from "components/Filter";
import { updateLocation } from "store/features/locations/locationsAction";

const ReferenceView = () => {
  const appContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(newLabClientObject);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [filter, setFilter] = useState({});
  const [checkboxes, setCheckboxes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState();

  const dispatch = useDispatch();
  const permission = useSelector(userGivenPermission);
  const searchTerms = ["Name", "Contact Name"];

  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    permission[CLIENT_MANAGEMENT]?.write && TABLE_QUICK_TOOLS.edit,
    "provider",
  ];

  const labLocations = useSelector(refLabsSelectors);

  const currentItems = (locs) => {
    if (!locs || locs.length === 0) return [];
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return locs.slice(indexOfFirstUser, indexOfLastUser);
  };

  const locationsToMap = useMemo(() => {
    return currentItems(filteredLocations);
  }, [filteredLocations]);

  const sortData = (schs, sortParam) => {
    if (sortDescending) {
      return [...schs].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...schs].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const getLabClients = async () => {
    try {
      await dispatch(fetchAllLocations({ siteType: "lab" }));
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.facilities && sortLS.facilities.sortBy) {
      setSortBy(sortLS.facilities.sortBy);
      setSortDescending(sortLS.facilities.sortDescending);
    }

    getLabClients();
  }, [appContext?.company]);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(locations);

  if (filteredLocations && filteredLocations.length > 0) {
    pageNumbers = getPageNumbers(filteredLocations);
  }

  useEffect(() => {
    if (labLocations && labLocations.length > 0) {
      formatFacility();
    }
  }, [labLocations]);

  const formatFacility = () => {
    let facilities = labLocations.map((item) => ({ ...item }));

    setLocations(facilities);
    setFilteredLocations(sortData(nestedFilter(facilities, filter), sortBy));
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.facilities = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  useEffect(() => {
    if (locations.length > 0) {
      setFilteredLocations(sortData(nestedFilter(locations, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    if (filteredLocations.length > 0) {
      handleSaveFilter();
      setFilteredLocations(sortData(filteredLocations, sortBy));
    }
  }, [sortBy, sortDescending]);

  const handleConfirmDelete = async (isConfirm) => {
    const deletedItemRow = itemToDelete;
    setItemToDelete(null);
    if (!isConfirm) {
      return;
    }
    try {
      setLoading(true);
      const isDeleted = await API.updateSiteArchive(deletedItemRow.id, true);
      dispatch(setMessage("Reference Lab deleted successfully", MESSAGE_MODES.success));
      if (isDeleted) {
        setFilteredLocations((prevLocations) => prevLocations.filter((location) => location.id !== deletedItemRow.id));
        setLocations((prevLocations) => prevLocations.filter((location) => location.id !== deletedItemRow.id));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const OnHandleLocation = async (loc) => {
    const findVal = locations.find((f) => loc.id !== f.id && checkValidity(f.name) == checkValidity(loc.name));
    if (findVal) {
      dispatch(setMessage("Reference Lab already exist", MESSAGE_MODES.error));
      return;
    }
    setOpenCreateModal(false);

    try {
      if (loc.isNew) {
        const res = await dispatch(createLabClient({ ...loc, siteType: "lab" }));
        if (res) {
          dispatch(setMessage("Reference Lab Created Successfully", MESSAGE_MODES.success));
        }
      } else {
        await dispatch(updateLabClient({ ...loc, siteType: "lab" }));
        dispatch(setMessage("Reference Lab Updated Successfully", MESSAGE_MODES.success));
      }
      setSelectItem(newLabClientObject);
    } catch (err) {
      console.log("Error", err);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
    }
  };

  const handleEdit = (item) => {
    setSelectItem({ ...item, isNew: false });
    setOpenCreateModal(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const nestedFilter = (targetArray, filters) => {
    let apiData = targetArray;

    apiData = apiData.map((item) => ({
      ...item, // Spread the existing item properties
      totalTests: item?.setting?.totalTests || 0, // Add or override the totalTests property
    }));

    if (Object.keys(filters).length === 0) return apiData;
    const filterKeys = Object.keys(filters);

    const models = apiData.filter((obj) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const handleCheckboxChange = (e, row) => {
    const filteredList = checkboxes.filter((c) => c !== row.id);
    if (e.target.checked) {
      filteredList.push(row.id);
    }
    setCheckboxes(filteredList);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        if (row.id !== "596daf9b-8f9d-451c-a28f-a458bac57ce4" && row.id !== "a081f285-4949-45bd-9516-ba2ff3e8d0b1") {
          handleEdit(row);
        }
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      default:
        break;
    }
  };

  const LAB_CLIENT_DROPDOWN = useMemo(() => {
    const hasDeletePermission = permission[CLIENT_MANAGEMENT]?.delete;
    const deleteOption = hasDeletePermission ? [{ value: "delete", title: "Archive" }] : [];

    return deleteOption;
  }, [permission[CLIENT_MANAGEMENT]?.delete]);

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card
              className={`strpied-tabled-with-hover  ${
                locationsToMap?.length === 1 || locationsToMap?.length === 2 ? "test-details-table" : ""
              }`}
            >
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("referenceLab")} ({filteredLocations ? formatNumber(filteredLocations.length) : 0})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  {permission[CLIENT_MANAGEMENT]?.write && (
                    <Icon
                      handleClick={() => {
                        const obj = { ...newLabClientObject };
                        setSelectItem({ ...obj, clientID: CONFIG.clientID });
                        setSelectItem({ ...obj, clientID: CONFIG.clientID });

                        setSelectItem({ ...obj, clientID: CONFIG.clientID });

                        setOpenCreateModal(true);
                      }}
                      label={"Create"}
                      title={"Create Facility"}
                      iconType={"addUserIcon"}
                    />
                  )}
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={pageNumbers} />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={locations.length === 0 && "overFlow-y-hidden"}
                  columns={REFERENCE_PERSONALIZE}
                  rows={locationsToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  widthToSkip={160}
                  selectedRows={checkboxes}
                  tools={quickTools}
                  handleCellClick={handleCellClick}
                  dropDownOptions={LAB_CLIENT_DROPDOWN}
                  handleDropDownClick={(type, row) => {
                    if (type === "Archive") {
                      if (
                        row.id !== "596daf9b-8f9d-451c-a28f-a458bac57ce4" &&
                        row.id !== "a081f285-4949-45bd-9516-ba2ff3e8d0b1"
                      ) {
                        setItemToDelete(row);
                      }
                    }
                  }}
                />
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Archive Reference Lab"
              message="Are you sure, you want to archive Client?"
              handleConfirm={handleConfirmDelete}
            />
          )}

          {openCreateModal && (
            <ReferenceLabModal
              handleClose={() => {
                setSelectItem(newLabClientObject), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleLocation}
              appContext={appContext}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReferenceView;
