import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";

const RangeBacteriaSelector = ({ options = [], onChange }) => {
  const [inputValue, setInputValue] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChangeSourceSite = (option) => {
    setSelectedOption(option);
    setInputValue("");
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === "input-change") {
      setSelectedOption(null);
      setInputValue(newValue);
    }
  };

  const addBacteriaInRange = () => {
    const value = selectedOption ? selectedOption.label : inputValue;
    onChange(value);
  };

  return (
    // <div className="form-group-wrapper">
    //   {/* <label className="modalLineHeaders">Select Or Type Bacteria</label> */}

    // </div>
    <Select
      className="w-100 siteSelector"
      options={options}
      blurInputOnSelect={true}
      menuPlacement="auto"
      placeholder="Bacteria"
      value={selectedOption}
      inputValue={inputValue}
      onChange={handleChangeSourceSite}
      onInputChange={handleInputChange}
      onBlur={addBacteriaInRange}
    />
  );
};

export default RangeBacteriaSelector;
