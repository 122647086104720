import { DataStore, syncExpression } from "@aws-amplify/datastore";
import { default as api, default as localApi } from "api";
import sidebarImage from "assets/img/sidebar-3.jpg";
import { API, Auth, graphqlOperation, Hub, Storage } from "aws-amplify";
import IdleModal from "components/IdleModal";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import EligibilityDetailsModal from "components/Modal/Eligibility/EligibilityDetailsModal";
import ImportFileStatusModal from "components/Modal/ImportFileStatusModal";
import PatientImportErrorModal from "components/Modal/PatientImportErrorModal";
import PatientProfileModal from "components/Modal/PatientProfileModal";
import TestLogsModal from "components/Modal/TestLogsModal";
import AdminNavbar from "components/Navbars/AdminNavbar";
import TestNoteModal from "components/Notes/TestNoteModal";
import TestDoneModal from "components/Patient/TestDoneModal";
import ResutlViewModal from "components/Result/ResultViewModal";
import Sidebar from "components/Sidebar/Sidebar";
import TestScheduleModal from "components/TestSchedule/TestScheduleModal";
import UpComingScheduleModal from "components/TestSchedule/UpComingScheduleModal";
import { UPLOAD_CSV_FILE, WHITE_LABEL_ROTUES } from "constant";
import { debounce } from "debounce";
import * as subscriptions from "graphql/subscriptions";
import moment from "moment";
import Papa from "papaparse";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState, useMemo } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useIdleTimer } from "react-idle-timer";
import { Route, Switch, useHistory, useLocation, Redirect } from "react-router-dom";
import adminRoutes from "routes/adminRoutes";
import siteRoutes from "routes/HSRoutes";
import labAdminRoutes from "routes/labAdminRoutes";
import labRoutes from "routes/labRoutes";
import LabTechRoutes from "routes/LabTechRoutes";
import whiteLabelLabRoutes from "routes/WhiteLabellabRoutes";
import useScanDetection from "use-scan-detection";
import { getListenerData, getCurrentUser } from "../../src/store/features/authentication/authenticationActions";
import { setPermission, setGlobalUser } from "store/features/authentication/authenticationSlice";
import TestDetails from "views/TestDetails";
import {
  clientIDInLocalStorage,
  connectedIDInLocalStorage,
  downloadDataAsCSV,
  formatExternalTest,
  formatPhoneNumber,
  formatTest,
  isValidBarcodeNumber,
  medFlowInLocalStorage,
  processAndAddColumnsIntoCsvFiles,
  roleInLocalStorage,
  showsInSS,
  SYSTEM_TIME_ZONE,
  parseJSONObject,
  getClientSpecificString,
  env,
} from "utils";
import AdminDashboard from "views/DashboardCharts/AdminDashboard";
import ClientsDashboard from "views/DashboardCharts/ClientsDashboard";
import LabDashboard from "views/DashboardCharts/LabDashboard";
import GQAPI from "views/GraphQL/gqApi";
import CreateOrderTest from "views/Order/CreateOrderTest";
import LoadOrdersModal from "views/Order/LoadOrdersModal";
import OrderTestModal from "views/Order/OrderTestModal";
import TestListAdmin from "views/Test/TestListAdmin";
import OrdersView from "views/Order/OrderView";
import PatientView from "views/GraphQL/PatientView";
import UnprocessedTestsApi from "views/GraphQL/UnprocessedTestsApi";
import LabTestTypesSetting from "views/TestTypeSetting/LabTestTypesSetting.js";
import TestSelectionModal from "components/Test/TestSelectionModal";
import DeleteTestApi from "views/GraphQL/DeleteTestApi";
import {
  AMAZON_CLIENT_RESULT,
  BUCKET_EXTERNAL_IMAGES,
  CONFIG,
  CRUD_TYPE,
  FILE_REPRESENT_TYPES_MAP,
  HEALTH_AND_SAFTEY,
  IGNORE_INS,
  RECEIVED_BYLAB,
  RESULT_CHECK,
  RESULT_PDF,
  ROUTES,
  countryListAllIsoData,
  BIO_LAB_ID,
} from "../constant";
import { AppContext } from "../context/app-context";
import { insurnaceSelector } from "store/features/insurances/insuranceSelectors";
import { setOpenInsuranceModal, setSelectedInsurance, fetchPayerAsync } from "store/features/insurances/insuranceSlice";
import {
  Client,
  Employee,
  EmployeeBarCodes,
  ExternalTest,
  Lab,
  Order,
  Site,
  Test,
  TestConsent,
  UploadRequest,
  UploadRequestMain,
  Users,
  GeneralSettingType,
  Documents,
  LencoBarcodes,
} from "../models";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import { useDispatch, useSelector } from "react-redux";
import InsuranceModal from "components/Insurances/InsuranceModal";
import ShowAlertMessage from "components/ShowAlertMessage";
import { messageSelector, testToPrintView, loadingSelector } from "store/features/general/generalSelectors";
import { showMessage, setTestPrintView } from "store/features/general/generalSlice";
import TestListAdminApi from "views/GraphQL/TestListCompletedTest/TestListAdminApi";
import AssignResultsTestModal from "components/Modal/AssignResultsTestModal";
import { showResultTest } from "store/features/resultsTests/resultsTestSelectors";
import { resetStateResultState } from "store/features/resultsTests/resultsTestSlice";
import ChangeResultModal from "components/Modal/ChangeResultModal";
import { setTestIdsToRemove } from "store/features/resultsTests/resultsTestSlice";
import RequisitionFormViewModal from "components/Modal/RequisitionForm/RequisitionFormViewModal";
import ShowPrintOptions from "components/Patient/ShowPrintOptions";
import useLocalStorageState from "util/useLocalStorageState";
import { updateLocationOnSubscription } from "store/features/locations/locationsSlice";
import { selectedDeviceSelector } from "store/features/general/generalSelectors";
import { setSelectedDevice } from "store/features/general/generalSlice";
import GlobalActionsComponent from "components/GlobalActionsComponent";
import { associatedPanelSelector } from "store/features/testDictionary/testDictionarySelectors";
import TestAssociatedViewModal from "views/Order/TestAssociatedViewModal";
import { emptyAssociatedPanel } from "store/features/testDictionary/testDictionarySlice";
import GenericPanelTestView from "views/TestTypeSetting/TestPanels/GenericPanelTestView";
import { genericModalViewSelector } from "store/features/genericTestsView/genericTestViewSelector";
import GenericPathogenView from "views/TestTypeSetting/TestPanels/GenericPathogenView";
import { setGlobalLab } from "store/features/authentication/authenticationSlice";
import { setCountryCode } from "store/features/authentication/authenticationSlice";
import PanelResultModal from "components/Modal/PanelResultModal";
import { fetchCompanySetting } from "store/features/companySetting/companySettingSlice";
import { formatDateOfBirthDOB } from "utils";
import DocumentPreviewModal from "components/Modal/DocumentPreviewModal";
import { resultPdfSelector } from "store/features/resultPdf/resultPdfSelector";
import { hideResultPdf } from "store/features/resultPdf/reslutPdfSlice";
import OrderView from "views/Order/OrderView";
import { setClientData } from "store/features/authentication/authenticationSlice";
import ResultUploadModal from "components/Result/ResultUploadModal";
import { selectedEmployeeForTestSelector } from "store/features/employees/employeesSelectors";
import { fetchAllLocations } from "store/features/locations/locationsSlice";
import { addRouteIfNotExists } from "utils";

function AdminNew() {
  const timeout = 18000000;
  const [showIdleModal, setShowIdleModal] = useState(false);
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [lastEvent, setLastEvent] = useState("Events Emitted on Leader");
  const [leader, setLeader] = useState(true);
  const [timedOut, setTimedOut] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessageText, setSuccessMessageText] = useState("");
  const [successBottomMessageText, setSuccessBottomMessageText] = useState("");
  const [errorMessageText, setErrorMessageText] = useState("");
  const [pageState, setPageState] = useState("New");
  const [role, setRole] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(null);
  const [scanBarcode, setScanBarcode] = useState();
  const [uploadPatientResult, setUploadPatientResult] = useState([]);
  const [uploadRequestData, setUploadRequestData] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [eligibilityDetail, setEligibilityDetail] = useState(null);
  const [notifySubs, setNotifySubs] = useState(null);
  const [uploadFileStatus, setUploadFileStatus] = useState(null);
  const [showOrderModal, setShowOrderModal] = useState(null);
  const [showOrderTestModal, setShowOrderTestModal] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const userRef = useRef();
  const [noteObject, setNoteObject] = useState(null);
  const { openInsuranceModal } = useSelector(insurnaceSelector);
  const permission = useSelector(userGivenPermission);
  const messageObj = useSelector(messageSelector);
  const selectedTestForPrint = useSelector(testToPrintView);
  const isGlobalLoader = useSelector(loadingSelector);
  const selectedDevice = useSelector(selectedDeviceSelector);
  const associatedPanelData = useSelector(associatedPanelSelector);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [docName, setDocName] = useState();

  const dispatch = useDispatch();

  const {
    simpleTestList,
    detailTestList,
    panelTests,
    showDetailModal,
    showSimpleModal,
    showPanalModal,
    testForResult,
  } = useSelector(showResultTest);

  const { showPdf, resultFileUrl, resultFileType, resultPdfTitle } = useSelector(resultPdfSelector);

  const employeeForTests = useSelector(selectedEmployeeForTestSelector);

  const { showGenericPathogenModal, showGenericModal, genericData, genericPathogenData } =
    useSelector(genericModalViewSelector);

  useLayoutEffect(() => {
    let userType = medFlowInLocalStorage.get();
    if (!userType || userType === null) handleLogOut();
  }, []);

  useEffect(() => {
    let roleSelected = roleInLocalStorage.get();
    setRole(roleSelected);
  }, []);

  const userData = useLocalStorageState("userData", null);

  const childItemsProcessed = (prev, record) => {
    const parentIndex = prev.findIndex((f) => f.id === record.id);

    if (parentIndex === -1) {
      prev.push(record);
    } else {
      prev.splice(parentIndex, 1, record);
    }
    return [...prev];
  };

  const getData = async () => {
    return await localApi.getCurrentUser();
  };

  useEffect(() => {
    if (notifySubs) {
      const { record, opType, main } = notifySubs;

      if (main) {
        setNotificationList((prev) => {
          const parentIndex = prev.findIndex((f) => f.id === record.id);
          if (record && record.status === "cancel") {
            return prev.filter((f) => f.id !== record.id);
          }
          if (parentIndex === -1) return [...prev]; // Return a copy even if no changes
          prev.splice(parentIndex, 1, { ...record, Items: [] });
          return [...prev];
        });
        if (uploadFileStatus && uploadFileStatus !== null) {
          setUploadFileStatus(record);
        }
      } else {
        setNotificationList((prev) => {
          const parentIndex = prev.findIndex((f) => f.id === record.requestID);

          if (parentIndex === -1) return [...prev]; // Return a copy even if no changes

          const updatedPrev = [...prev];
          const items = updatedPrev[parentIndex].Items || [];

          const itemIndex = items.findIndex((f) => f.id === record.id);

          // If it's an INSERT operation or the item doesn't exist yet, push the record
          if (itemIndex === -1) {
            items.push(record);
          } else {
            items.splice(itemIndex, 1, record);
          }

          updatedPrev[parentIndex].Items = items;
          return updatedPrev;
        });
        if (uploadFileStatus) {
          setUploadFileStatus((prev) => {
            return { ...prev, Items: childItemsProcessed(prev.Items || [], record) };
          });
        }
        if (uploadPatientResult && uploadPatientResult.length > 0) {
          setUploadPatientResult((prev) => childItemsProcessed(prev, record));
        }
      }
    }
  }, [notifySubs]);

  let history = useHistory();

  const handleCloseModal = () => {
    setShowIdleModal(false);
  };

  const emptyUploadPatientResult = async () => {
    try {
      await api.emptySiteUpdateStatus();
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  const handleLogOut = async () => {
    setShowIdleModal(false);
    try {
      await DataStore.clear();
      await Auth.signOut();
      medFlowInLocalStorage.clear();
      roleInLocalStorage.clear();
      showsInSS.clear();
      // console.log("PAUSED");
      handlePause();
      history.push("/login");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  const checkEligibilityDetails = async (item) => {
    const patientInsurance = item.insuranceCompanyCode;

    if (IGNORE_INS.includes(patientInsurance)) return;

    if (item && item.apiData) {
      const data = parseJSONObject(item.apiData);

      if (data.fileName) {
        try {
          const eligData = await localApi.getEligibilityFile({
            path: data.fileName,
            employeeID: item.mdID || item.employeeID,
          });
          return setEligibilityDetail({
            ...item,
            ...eligData?.elig,
            ...(eligData?.error && { error: eligData?.error }),
          });
        } catch (err) {
          console.log("Error:-", err.message);
          return null;
        }
      } else {
        return setEligibilityDetail({
          ...item,
          ...data?.elig,
          // ...(employee && { payerId: employee.insuranceCompany, employee })
        });
      }
    } else {
      return setEligibilityDetail({
        ...item,
        // ...(employee && { payerId: employee.insuranceCompany, employee })
      });
    }
  };

  const getDevices = () => {
    console.log("Initializing getDevices function...");

    if (typeof BrowserPrint === "undefined") {
      console.error("BrowserPrint is not defined. Ensure that the BrowserPrint library is properly included.");
      return;
    }

    console.log("BrowserPrint object:", BrowserPrint);
    console.log("Document readyState:", document.readyState);

    if (document.readyState === "complete") {
      console.log("Document is fully loaded. Checking for BrowserPrint...");

      if (BrowserPrint) {
        console.log("BrowserPrint is available. Attempting to get local devices...");

        BrowserPrint.getLocalDevices(
          (device_list) => {
            if (device_list.length === 0) {
              console.warn("No devices found. Ensure the printer is connected and turned on.");
            } else {
              console.log("Device list retrieved:", device_list);
              dispatch(setSelectedDevice(device_list[0]));
            }
          },
          (error) => {
            console.error("Error getting local devices:", error);
          },
          "printer"
        );
      } else {
        console.error("BrowserPrint is not available after document is loaded.");
      }
    } else {
      console.warn("Document is not fully loaded. Try again later.");
    }
  };

  const getLabClients = async () => {
    try {
      await dispatch(fetchAllLocations({ noShowLoader: true, siteType: "lab" }));
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  useEffect(() => {
    getLabClients();
    if (document.readyState === "complete") {
      getDevices();
    } else {
      window.addEventListener("load", getDevices);
      return () => window.removeEventListener("load", getDevices);
    }
  }, []);

  const getPatientName = (test) => {
    const getDemographics = (demographics) => {
      if (typeof demographics === "string") {
        try {
          return JSON.parse(demographics);
        } catch (e) {
          return {};
        }
      }
      return demographics || {};
    };

    const employeeDemographics = getDemographics(test?.employee_demographics);
    const orderEmployeeDemographics = getDemographics(test?.order?.employee_demographics);

    const firstName = test?.firstName ?? employeeDemographics.firstName ?? orderEmployeeDemographics.firstName;
    const lastName = test?.lastName ?? employeeDemographics.lastName ?? orderEmployeeDemographics.lastName;

    return `${lastName || ""}, ${firstName || ""}`.trim();
  };

  const printBarcode = async (test) => {
    if (!selectedDevice) {
      setErrorMessageText("Printer Device not Connected");
      return;
    }

    const errorCallback = (errorMessage) => {
      setErrorMessageText("Error: " + errorMessage);
    };

    let data;
    // Check if employee_demographics is a JSON string or an object
    if (typeof test?.employee_demographics === "string") {
      data = JSON.parse(test?.employee_demographics);
    } else if (typeof test?.employee_demographics === "object") {
      data = test?.employee_demographics;
    }

    // Ensure swabtype is valid before processing
    const swabTypeArray = data?.swabtype ? data?.swabtype?.split(",").map((type) => type.trim()) : [];

    const name = getPatientName(test);

    const serial = test?.barcode;

    const dob = formatDateOfBirthDOB(data?.dob);
    const collectionDate = moment(test?.createdAt).format("MM/DD/YY HH:mm");

    if (!serial) {
      setErrorMessageText("Test is not created");
      return;
    }

    const testTypeResult = await api.getTestTypesByID(data?.testTypeID);

    function generateAndSendZpl(nameString, collectionSourceString, dob) {
      const zpl = `^XA
      ^FO10,30^A0,30,30^FD${nameString}^FS
      ^FO400,30^A0,30,30^FD${testTypeResult?.code}^FS    /* Display 'ddd' to the right of nameString */
      ^FO10,70^A0,30,30^FD${dob}^FS
      ^FO10,110^A0,30,30^FD${collectionSourceString}^FS
      ^BY3,2,70
      ^FO10,153^BC^FD${serial}^FS
      ^XZ`;
      selectedDevice?.send(zpl, undefined, errorCallback);
    }

    if (swabTypeArray && swabTypeArray.length > 0) {
      for (const item of swabTypeArray) {
        generateAndSendZpl(`${name}`, `CL: ${collectionDate} - ${item}`, `DOB: ${dob}`);
      }
    } else {
      generateAndSendZpl(`${name}`, `CL: ${collectionDate}`, `DOB: ${dob}`);
    }
  };

  const handleOnIdle = (event) => {
    setShowIdleModal(true);
  };

  const handleOnActive = (event) => {
    setTimedOut(false);
  };

  const handleOnAction = (event) => {
    setTimedOut(false);
  };

  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();
  const handleStart = () => start();

  const {
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
    getTotalIdleTime,
    getLastIdleTime,
    isIdle,
    pause,
    resume,
    start,
    isLeader,
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: false,
    },
  });

  // useEffect(() => {
  //   setRemaining(getRemainingTime());
  //   setLastActive(getLastActiveTime());
  //   setElapsed(getElapsedTime());
  //   setInterval(() => {
  //     setRemaining(getRemainingTime());
  //     setLastActive(getLastActiveTime());
  //     setElapsed(getElapsedTime());
  //   }, 1000);
  // }, []);

  const removeUserFromLC = (e) => {
    e.preventDefault();
    e.returnValue = "";
    alert("Are you sure you want to close");
    return "Not";
  };

  useEffect(() => {
    // window.addEventListener("beforeunload", removeUserFromLC);
    DataStore.stop();
    // return () => {
    //   window.removeEventListener("beforeunload",removeUserFromLC);
    // };
  }, []);

  useEffect(() => {
    if (showIdleModal) {
      const timeout = setTimeout(() => {
        if (showIdleModal) {
          handleLogOut();
        }
      }, 60000); // TODO: - This time is the extra time you want to see how long they're idle for until they get logged out 300000
      return () => clearTimeout(timeout);
    }
  }, [showIdleModal]);

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState(process.env.REACT_APP_COLOR);
  const [hasImage, setHasImage] = React.useState(true);
  const [userRoutes, setUserRoutes] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [labs, setLabs] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [pendingTests, setPendingTests] = React.useState([]);
  const [invalidTests, setInvalidTests] = React.useState([]);
  const [completedTests, setCompletedTests] = React.useState([]);
  const [unprocessedTests, setUnprocessedTests] = React.useState();
  const [unSubmittedTests, setUnsubmittedTests] = React.useState();
  const [externalTest, setExternalTest] = useState([]);
  const [newTests, setNewTests] = React.useState([]);
  const [totalTests, setTotalTests] = React.useState([]);
  const [adminTotalTests, setAdminTotalTests] = React.useState([]);
  const [userLab, setUserLab] = React.useState(null);
  const [userLabName, setUserLabName] = React.useState(null);
  const [userSiteName, setUserSiteName] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [testFilter, setTestFilter] = useState(null);
  const [eventObject, setEventObject] = useState(0);
  const [eventOrder, setEventOrder] = useState(null);
  const [patientObject, setPatientObject] = useState(0);
  const [testObject, setTestObject] = useState(0);
  const [isShowUpdated, setIsShowUpdated] = useState(null);
  const [showTestLogModal, setShowTestLogModal] = useState(null);
  const [showPatientModal, setShowPatientModal] = useState(null);
  const [testScheduleModal, setTestScheduleModal] = useState(null);
  const [openTestScheduleModal, setOpenTestScheduleModal] = useState("");
  const [patientTestViewModal, setPatientTestViewModal] = useState(null);
  const [resultView, setResultView] = useState(null);
  const [requisitionView, setRequisitionView] = useState(null);
  const [openUpComingSchedule, setOpenUpComingSchedule] = useState(false);
  const [openLoadOrdersModal, setLoadOrderModal] = useState(false);
  const [orderFiles, setOrderFiles] = useState({});
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [downloadIndicator, setDownloadIndicator] = useState({ isShow: false });
  const [locationObject, setLocationObject] = useState(0);
  const [empId, setEmpId] = useState();

  const location = useLocation();
  const mainPanel = React.useRef(null);

  useScanDetection({
    onComplete: setScanBarcode,
    minLength: 3,
  });

  //setTimeout(() => setScanBarcode("2409059001"), 20000);
  useEffect(() => {
    if (scanBarcode && isValidBarcodeNumber(scanBarcode)) {
      scanBarcodeFunction(scanBarcode);
    }
  }, [scanBarcode]);

  const debouncedWriteFromAPI = useCallback(
    debounce((files, user) => {
      showUploadFile(files, user);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedWriteFromAPI(orderFiles, user);
  }, [orderFiles]);

  const showUploadFile = async (orderFiles, user) => {
    const { files, uploadStatus, selectedSite } = orderFiles;
    if (files?.length > 0) {
      const pendingFiles = uploadStatus.filter((f) => f.status === "pending").length;

      if (pendingFiles > 0) {
        return;
      }

      const uploadedFileNames = uploadStatus[0]?.fileName;
      let res = null;

      const param = {
        siteID: selectedSite?.value || "",
        files: orderFiles.uploadStatus.filter((f) => f.status === "done").map((f) => f.fileName),
        createdBy: user?.sub,
        createdByName: user?.name,
        uploadCallType: UPLOAD_CSV_FILE.test,
        timezone: SYSTEM_TIME_ZONE,
        test_date: moment(orderFiles.testDate).format("MMDDYY"),
        filesStatus: orderFiles.uploadStatus.map((f) => ({
          status: f.status === "done" ? "pending" : f.status,
          fileName: f.fileName,
          id: f.id,
          floorID: f.floorID,
        })),
        testTypeID: orderFiles.testTypeID,
      };

      res = await localApi.createUploadResult({
        ...param,
        status: "pending",
        title: `Upload ${param.uploadCallType}`.toUpperCase(),
        fileName: uploadedFileNames,
        fileType: "pdf",
      });

      await localApi.saveOrdersFiles({
        ...param,
        orderDate: orderFiles.testDate,
        requestID: res.id,
        siteName: selectedSite?.label || "",
      });

      if (res) setNotificationList([{ ...res, Items: [] }]);

      setOrderFiles({});
    }
  };

  const parseSrNoObject = (sr_no, barcode, user) => {
    const newObj = { accession_no: barcode, date: moment().toISOString(), name: user.name };
    let arr = [];

    if (sr_no == "0") {
      arr.push(newObj);
    } else {
      try {
        arr = typeof sr_no === "string" ? JSON.parse(sr_no) : sr_no;
        arr[0] = newObj;
      } catch (error) {
        console.log("Parser Error:", error);
      }
    }

    return JSON.stringify(arr);
  };

  const updateOldScanning = async (barcode) => {
    try {
      const findTest = await api.fetchTestFromByClientID({
        barcode: `${CONFIG.whiteLabelID}-${barcode}`,
        clientID: CONFIG.clientID,
      });

      if (findTest) {
        setLoading(true);
        const { id, sequenceNo } = findTest;
        const scanByLabUserID = user?.sub;
        const scanByLabUserName = user?.name;
        const sr_no = parseSrNoObject(findTest.sr_no, findTest.barcode, user);

        if (!findTest.scanByLabUserID) {
          await Promise.all([
            api.updateTestRecord({
              id,
              scanByLabUserID,
              scanByLabUserName,
              pcrStatus: RECEIVED_BYLAB,
              sr_no,
              labelPrinted: 2,
              _version: findTest._version,
            }),
            api.addLogs({
              testID: id,
              slug: "Specimen Scanned In",
              newValue: "Sample Received",
              sequenceNo,
              userID: scanByLabUserID,
              userName: scanByLabUserName,
            }),
          ]);
        }
        history.push(`/admin/test/${id}`);
      }
      setScanBarcode("");
      setLoading(false);
    } catch (error) {
      console.log("Error:", error);
      setLoading(false);
    }
  };

  const updateNewScanning = async (barcode) => {
    try {
      const findTest = await api.fetchTestDetail({
        id: `${CONFIG.whiteLabelID}-${barcode}`,
      });
      if (findTest && !findTest.labReceiveDate) {
        setPatientTestViewModal({ order: findTest, type: "order", uiType: "testManagmentUi" });
      }
      setScanBarcode("");
    } catch (ex) {
      console.log("Error:", error);
    }
  };

  const scanBarcodeFunction = async (barcode) => {
    if (!barcode) return;

    if (CONFIG.clientID === BIO_LAB_ID) {
      updateOldScanning(barcode);
    } else {
      updateNewScanning(barcode);
    }
  };

  //
  const getRoutes = (routes) => {
    return routes?.map((prop, key) => {
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} render={(props) => <prop.component {...props} />} key={key} />;
      } else {
        return null;
      }
    });
  };

  const fetchUserGroups = async () => {
    const admins = await localApi.fetchAllUsersInGroup("Admins");
    const labs = await localApi.fetchAllUsersInGroup("Labs");
    const testers = await localApi.fetchAllUsersInGroup("Testers");
    const employers = await localApi.fetchAllUsersInGroup("Employers");
    const clients = await localApi.fetchAllUsersInGroup("Clients");
    return Promise.all([admins, labs, testers, employers, clients]).then((values) => [
      admins,
      labs,
      testers,
      employers,
      clients,
    ]);
  };

  const fetchUserFromLocalDB = async (id = "") => {
    const admins = await localApi.getUserFromLocalDB(id);

    setUsers(
      admins.sort((a, b) =>
        a?.userRole?.toLowerCase() > b?.userRole?.toLowerCase()
          ? 1
          : a?.userRole?.toLowerCase() < b?.userRole?.toLowerCase()
          ? -1
          : 0
      )
    );
  };

  const formatUserObject = (user, role) => {
    const newUser = {};
    const email = user.Attributes.find((attr) => {
      return attr.Name === "email";
    }).Value;
    newUser.email = email;
    const phone = user.Attributes.find((attr) => {
      return attr.Name === "phone_number";
    }).Value;
    const firstName = user.Attributes.find((attr) => {
      return attr.Name === "custom:firstName";
    })?.Value;
    const lastName = user.Attributes.find((attr) => {
      return attr.Name === "custom:lastName";
    })?.Value;

    const note = user.Attributes.find((attr) => {
      return attr.Name === "custom:note";
    })?.Value;

    const adRole = user.Attributes.find((attr) => {
      return attr.Name === "custom:adRole";
    })?.Value;

    const clientID = user.Attributes.find((attr) => {
      return attr.Name === "custom:clientID";
    })?.Value;

    const connectedID = user.Attributes.find((attr) => {
      return attr.Name === "custom:connectedID";
    })?.Value;

    newUser.name = firstName && lastName ? `${firstName} ${lastName}` : "-";
    newUser.phone_number = formatPhoneNumber(phone);
    newUser.phone = phone;
    newUser.username = user.Username;
    newUser.firstName = firstName ? `${firstName}` : "-";
    newUser.lastName = lastName ? `${lastName}` : "-";
    newUser.status = user.UserStatus;
    newUser.role = role === "Employee" ? "Crew" : role === "Employer" ? "Health & Safety" : role;
    newUser.associated = adRole || note || "N/A";
    newUser.clientID = clientID;
    newUser.connectedID = connectedID;
    const obj = Object.assign(
      {
        role: role === "Employee" ? "Crew" : role === "Employer" ? "Health & Safety" : role,
      },
      newUser
    );
    return { ...obj, label: `${firstName} ${lastName}`, value: user.Username };
  };

  const realTimeStatus = (record, opType, main = false) => {
    setNotifySubs({ record, opType, main });
  };

  const handleSelect = async (results, event) => {
    if (event) {
      event.stopPropagation();
      setUploadFileStatus(results);
    } else {
      setUploadPatientResult(results.Items);
    }
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      return;
    }
    setLoading(true);
    const testList = [...(openDeleteConfirmation?.tests || [])];
    let ttlTest = testList.length;
    if (ttlTest === 0) {
      return;
    }

    await deleteTests(testList);

    setLoading(false);
    openDeleteConfirmation.afterDeletedTest(testList);
    setOpenDeleteConfirmation(null);
  };

  const fetchAdmins = async () => {
    const list = [];
    const admins = await localApi.fetchUsersInGroup("Admins");
    list.push(...admins.map((admin) => formatUserObject(admin, "Admin")));
    setAdmins(list);
    return admins;
  };

  const fetchClients = async () => {
    const models = await localApi.getClients();
    setClients(models);
    if (models && models.length > 0) dispatch(setClientData(models[0]));
  };

  const formatFilters = (params, dateFilter) => {
    const filterParam = params;
    Object.assign(filterParam, { status: "Processed" });

    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start)
        Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
      if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
    }
    return filterParam;
  };

  const downloadRequisition = async (params) => {
    try {
      const { filteredTests, allSelected, checkboxes, filter = {}, timeFilter } = params;
      let items = [];
      let isDownloadAll = false;

      if (allSelected) {
        setLoading(true);
        const filterParam = formatFilters(filter, timeFilter);
        items = await api.splitReqForm({ ...filterParam, reqForm: "y" });
        setLoading(false);
        isDownloadAll = true;
      } else if (checkboxes.length > 0) {
        const selectedItemsData = filteredTests.filter((item) => checkboxes.includes(item.id));
        items = selectedItemsData.map((item) => `${item.barcode}.pdf`);
      }

      if (items.length > 0) {
        await downloadRequisitionFile({ items, isDownloadAll });
      } else {
        setErrorMessageText("No File to downlaod");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      setErrorMessageText("Fail to downlaod");
    }
  };

  const downloadRequisitionFile = async (paramObj) => {
    try {
      const { items, isDownloadAll } = paramObj;
      const ttlRecord = isDownloadAll ? items.length : Math.ceil(items.length / 20);

      setDownloadIndicator({ isShow: true, totalRecords: ttlRecord, processCount: 0 });
      let resp = await processChunks(paramObj);
      let totalFiles = resp.length;

      let completedFiles = 0;

      if (resp.length > 0) {
        // Use Promise.allSettled to download the requisition files
        await Promise.allSettled(
          resp.map(async (file) => {
            try {
              await api.downloadRequisitionZip(file.fileName, "req_form.zip", BUCKET_EXTERNAL_IMAGES);
              completedFiles++;
              setDownloadIndicator({ isShow: true, totalRecords: totalFiles, processCount: completedFiles });
            } catch (downloadError) {
              completedFiles++;
              setDownloadIndicator({ isShow: true, totalRecords: totalFiles, processCount: completedFiles });
            }
          })
        );
      }
      setDownloadIndicator({ isShow: false });
    } catch (error) {
      setDownloadIndicator({ isShow: false });
    }
  };

  function chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  const processChunks = async ({ items, isDownloadAll }) => {
    try {
      const dataToProcess = isDownloadAll ? items : chunkArray(items, 20);

      const result = await Promise.allSettled(
        dataToProcess.map((chunk) => api.downloadRequisitionForm(isDownloadAll ? chunk : { ids: chunk }))
      );

      // Process the settled promises
      const successfulResults = result
        .filter((promiseResult) => promiseResult.status === "fulfilled")
        .map((fulfilledResult) => fulfilledResult.value);

      return successfulResults;
    } catch (error) {
      console.error("Error processing chunks:", error);
    }
  };

  const fetchSites = async (params) => {
    const models = await localApi.getSites(params);
    // setSites(models.filter((s) => s.isArchive === false));
    setSites(models?.filter((s)=> !s.isArchive && s.siteType !== "lab"));
  };

  const fetchUploadRequest = async () => {
    const models = await localApi.fetchUploadRequest(user);

    setNotificationList(models);
  };
  const fetchSitesByUser = async (siteID) => {
    const models = await localApi.getUserSiteData(siteID);
    setUserSiteName(models[0]?.name);
    return models;
  };

  const fetchSitesByClient = async (clientID) => {
    const models = await localApi.getSitesByClient(clientID);
    return models;
  };

  const findTotalTestsBySites = async (sites) => {
    const passFailClients = await localApi.getResultClients();
    const tests = [];
    sites &&
      sites?.forEach((site) => {
        return site?.tests?.map((test) => {
          return tests.push(formatTest(test, passFailClients));
        });
      });
    setTotalTests(tests);

    return tests;
  };

  const fetchExternalTests = async () => {
    const models = await localApi.getExternalTest();

    setExternalTest(models.map((t) => formatExternalTest(t)));
  };

  const updatedShow = (obj) => {
    if (obj) {
      setIsShowUpdated(obj);
    } else {
      setIsShowUpdated(null);
    }
  };

  const getClientName = (id) => {
    if (!id) return "";
    if (clients) {
      const client = clients.find((c) => c.id === id);
      if (client) return client.name;
    }
    return "";
  };

  const parseResult = (test) => {
    if (!test) return "";

    if (test.resultDetails) return "View Detail";
    if (test.result === "Details") return "View Detail";
    if (!test.result) return "";
    if (clients) {
      const client = clients.find((c) => c.id === test.clientID);
      if (client && client.resultType === "P/F") {
        return AMAZON_CLIENT_RESULT[test.result];
      }
    }

    return test.result && test.result.charAt(0).toUpperCase() + test.result.slice(1);
  };

  const parseTestResultForPDF = (test) => {
    if (!test) return "";

    if (clients) {
      const client = clients.find((c) => c.id === test.clientID);
      if (client && client.resultType === "P/F") {
        return AMAZON_CLIENT_RESULT[test.result];
      }
    }

    if (test.result === "Not Detected") return test.result;
    return test.result && RESULT_PDF[test.result.toLowerCase()];
  };

  const getEmployeeByID = async (id) => {
    let emp = await localApi.getRecordById(id, "employee");
    if (emp && emp.length > 0) {
      if (CONFIG.isWhiteLabel && emp[0]?.mdID) {
        return `BRM-002-${emp[0]?.mdID}` || id;
      }
      return emp[0]?.mdID || id;
    }
    return id;
  };

  const loadEmp = async () => {
    let id = await getEmployeeByID(resultView.employeeID);
    setEmpId(id);
  };

  useEffect(() => {
    if (resultView) {
      loadEmp();
    }
  }, [resultView]);

  const showDocumentPreview = async () => {
    setIsPreviewModalOpen(true);
    const result = await Storage.get(`results/${env}/${resultView?.clientID}/${resultView?.barcode}.pdf`, {
      bucket: "employee-external-tests",
      download: false,
      contentDisposition: "inline",
      Bucket: "employee-external-tests",
      contentType: "application/pdf",
    });

    setFileUrl(result);
    setFileType("pdf");
  };

  const fetchLabs = async () => {
    const models = await localApi.getLabs();
    setLabs(models);
    const labData = models;

    if (user.isLab() || user.isSite()) {
      const lab = labData.length === 1 ? labData[0] : labData.find((l) => l.id === connectedIDInLocalStorage.get());

      if (lab) {
        dispatch(setGlobalLab(lab));
        setUserLab(lab);
        setUserLabName(lab.name);
        dispatch(
          setCountryCode(
            countryListAllIsoData.find((item) => item.name === lab.country || item.code === lab.country)?.code
          )
        );
      }
    }
  };

  const fetchPendingTests = async (ids) => {
    if (ids) {
      const newList = pendingTests.filter((t) => ids.indexOf(t.id) === -1 && (t.invalidResultStatus || 0) === 0);
      setPendingTests(newList);
      return;
    }
    const params = { pendingRelease: "Pending" };
    if (CONFIG.isWhiteLabel) {
      Object.assign(params, { labID: connectedIDInLocalStorage.get() || "A" });
    }
    const models = await localApi.getTestListFromPG(params);

    const tests = models.rows
      .map((test) => {
        return formatTest(test);
      })
      .filter((f) => (f.invalidResultStatus || 0) === 0);
    setPendingTests(tests);
  };

  const fetchInvalidTests = async (ids) => {
    if (ids) {
      const newList = invalidTests.filter((t) => ids.indexOf(t.id) === -1 && (t.invalidResultStatus || 0) === 0);
      setInvalidTests(newList);
      return;
    }
    const models = await localApi.getTestListFromPG({ limit: 1000, page: 1, result: "Invalid" });
    const tests = models.rows
      .map((test) => {
        return formatTest(test);
      })
      .filter((f) => (f.invalidResultStatus || 0) === 0);
    setInvalidTests(tests);
  };

  const fetchAllAdminTests = async () => {
    const passFailClients = await localApi.getResultClients();
    const models = await localApi.getTotalAdminTests();
    const tests = models.map((test) => {
      return formatTest(test, passFailClients);
    });
    setAdminTotalTests(tests);
  };

  const fetchNewTests = async () => {
    const models = await localApi.getCurrentLabUserTests();
    setNewTests(models);
    return models;
  };

  const onSubscriptionTrigger = debounce(() => refreshDataOnSubscribe(), 6000);

  const refreshDataOnSubscribe = () => {
    const userType = medFlowInLocalStorage.get() || "A";
    if (userType === "L" || userType === "LT") {
      fetchNewTests();
    }
  };
  const [user, setUser] = useState();

  const loadSiteData = async () => {
    try {
      const connectedID = connectedIDInLocalStorage.get() || "A";
      if ("A" !== connectedID) {
        const usites = await fetchSitesByUser(connectedID);
        findTotalTestsBySites(usites);
      }
    } catch (ex) {
      console.log("Error", ex);
    }
  };

  const setOrderObject = (event, obj) => {
    if (!obj) return;

    if (obj.clientID !== CONFIG.clientID) {
      return;
    }
    setEventOrder({ opType: event, element: obj });
  };

  useEffect(() => {
    if (!testObject) return;
    const eventType = testObject.opType;
    const model = formatTest(testObject.element);
    const result = model.result;

    if (result && result.toLowerCase() === RESULT_CHECK[result.toLowerCase()]) {
      const newList = [...pendingTests];
      const index = pendingTests.findIndex((t) => t.id === model.id);

      if (
        (eventType === "DELETE" || (model.status === "Processed" && result.toLowerCase() === "positive")) &&
        index !== -1
      ) {
        newList.splice(index, 1);
      } else if (
        (eventType === "INSERT" || eventType === "UPDATE") &&
        index === -1 &&
        (model.status === "Pending" || result.toLowerCase() === "inconclusive" || result.toLowerCase() === "invalid")
      ) {
        newList.unshift(model);
      } else if (eventType === "UPDATE" && index !== -1) {
        newList.splice(index, 1, model);
      }
      setPendingTests(newList.filter((f) => (f.invalidResultStatus || 0) === 0));
    }
    // const newList = [...invalidTests];
    // const index = invalidTests.findIndex((t) => t.id === model.id);
    // if (eventType === "DELETE" && index !== -1) {
    //   newList.splice(index, 1);
    // } else if ((eventType === "INSERT" || eventType === "UPDATE") && index === -1) {
    //   newList.unshift(model);
    // } else if (eventType === "UPDATE" && index !== -1) {
    //   newList.splice(index, 1, model);
    // }
    // setInvalidTests(newList.filter((f) => (f.invalidResultStatus || 0) == 0));
  }, [testObject]);

  const onSubscriptionUpdateTestData = (model, event) => {
    try {
      const noSubs = ROUTES.some((r) => window.location.href.includes(r));
      if (noSubs) return;

      const userType = medFlowInLocalStorage.get() || "A";
      const connectedID = connectedIDInLocalStorage.get() || "A";

      if (userType === "A" || CONFIG.isWhiteLabel) {
        if (CONFIG.isWhiteLabel && (model.clientID !== CONFIG.clientID || model.labID !== CONFIG.whiteLabelID)) {
          return;
        }
        setTestObject({ opType: event, element: model });
      } else if (
        ((userType === "L" || userType === "LT") && model.labID === connectedID) ||
        (userType === "E" && model.siteID === connectedID)
      ) {
        setEventObject({ opType: event, element: model });
      }
    } catch (ex) {
      console.log("[onSubscriptionUpdateTestData]", ex);
    }
  };

  const onSubscriptionUpdateEmployeeData = (model, event) => {
    try {
      const isOnPatientView = window.location.href.includes("patient");
      if (!isOnPatientView) return;

      if (CONFIG.isWhiteLabel && model.clientID !== CONFIG.clientID) {
        return;
      }

      if (event === 'INSERT' && CONFIG.siteID && model.siteID !== CONFIG.siteID) {
        return;
      }

      setPatientObject({ opType: event, element: model });
    } catch (ex) {
      console.log("[onSubscriptionUpdateEmployeeData]", ex);
    }
  };

  const onSubscriptionUpdateSites = (model, event) => {
    try {
      const isOnLocationView = window.location.href.includes("testtypes");
      if (!isOnLocationView) return;

      if (CONFIG.isWhiteLabel && model.clientID !== CONFIG.clientID) {
        return;
      }
      dispatch(updateLocationOnSubscription({ model, event }));

      // setLocationObject({ opType: event, element: model });
    } catch (ex) {
      console.log("[onSubscriptionUpdateEmployeeData]", ex);
    }
  };

  React.useEffect(() => {
    let apiSubscription = null;
    let showSubscription = null;
    let showCreateSubscription = null;
    let uploadRequestGQLSubscription = null;
    let uploadRequestMainGQLSubscription = null;
    let orderUpdateGQLSubscription = null;
    let orderCreateGQLSubscription = null;
    let createRequestGQLSubscription = null;
    let patientSubscriptionUpdate = null;
    let patientSubscriptionCreate = null;
    let patientSubscriptionDelete = null;
    let testUpdateSubscription = null;
    let clientSubscription = null;
    const userType = medFlowInLocalStorage.get() || "A";

    if (userType === "A" && clientIDInLocalStorage.get() && connectedIDInLocalStorage.get()) {
      setUserRoutes(labAdminRoutes);
    } else if (userType === "A") {
      setUserRoutes(adminRoutes);
    } else if (userType === "L") {
      setLabRoutes();
    } else if (userType === "LT") {
      setLabRoutes();
    } else if (userType === "E") {
      setUserRoutes(siteRoutes);
    }

    const getUSer = async () => {
      const days = 2;
      const date = moment().subtract(days, "days").format("YYYY-MM-DD");
      const user = await localApi.getCurrentUser();
      let getUserRoles = await localApi.getEmployeeRoles(user?.sub);
      dispatch(setPermission(getUserRoles[0]?.userPermission || null));
      dispatch(setGlobalUser(getUserRoles[0] || null));
      if (getUserRoles[0]?.labAdmin) {
        const currentUser = getUserRoles[0];
        connectedIDInLocalStorage.save(currentUser.labAdmin);
        const roles = typeof currentUser.roles === "string" ? JSON.parse(currentUser.roles) : currentUser.roles;
        const clientRole = roles.find((f) => f.role === "Client");
        if (clientRole) clientIDInLocalStorage.save(clientRole.relatedId);
      }

      let siteID = null;

      if (CONFIG.isWhiteLabel) {
        const currentUser = getUserRoles[0];
        const roles = typeof currentUser.roles === "string" ? JSON.parse(currentUser.roles) : currentUser.roles;
        const labRoles = roles.filter(
          (f) =>
            f.role === "Lab" ||
            f.role === "Labs" ||
            f.role.includes("LabTech") ||
            f.role === "Employer" ||
            f.role === "Employers" ||
            f.role === HEALTH_AND_SAFTEY ||
            f.role.includes("Provider")
        );
        let labRole = null;
        if (labRoles.length === 1) {
          labRole = labRoles[0];
        } else if (labRoles.length > 1) {
          labRole = labRoles.find((r) => r.relatedId === connectedIDInLocalStorage.get());
        }
        if (labRole) {
          siteID = labRole.role.includes("Employer") || labRole.role.includes("Provider") ? labRole.relatedId : null;

          CONFIG.whiteLabelID = labRole.labID;
          CONFIG.clientID = labRole.clientID;
          CONFIG.siteID = siteID;
          CONFIG.basename = labRole.title;
        }
      }

      let syncExpressionsByUserType = [];
      if (user) {
        userRef.current = user;
        if (user.isAdmin() || user.isLab() || user.isSite() || user.isClient()) {
          syncExpressionsByUserType = [
            syncExpression(TestConsent, () => {
              return (test) => test.employeeID("eq", "123");
            }),
            syncExpression(EmployeeBarCodes, () => {
              return (emp) => emp.employeeID("eq", "123");
            }),
            syncExpression(Users, () => {
              return (emp) => emp.id("eq", "123");
            }),
            syncExpression(Employee, () => {
              return (test) => test.id("eq", "123");
            }),
            syncExpression(ExternalTest, () => {
              return (test) => test.id("eq", "000");
            }),
            syncExpression(Order, () => {
              return (test) => test.id("eq", "000");
            }),
          ];
          if (user.isAdmin()) {
            syncExpressionsByUserType.push(
              syncExpression(Test, () => {
                return (test) => test.status("ne", "Processed");
              })
            );
          }
          if (user.isAdmin() && connectedIDInLocalStorage.get() && clientIDInLocalStorage.get()) {
            syncExpressionsByUserType.push(
              syncExpression(Test, () => {
                return (test) => test.labID("eq", connectedIDInLocalStorage.get()); //get All Tests from Lab ID
              })
            );

            syncExpressionsByUserType.push(
              syncExpression(Lab, () => {
                return (test) => test.id("eq", connectedIDInLocalStorage.get()); //get All Labs from Lab ID
              })
            );

            syncExpressionsByUserType.push(
              syncExpression(Client, () => {
                return (test) => test.id("eq", clientIDInLocalStorage.get()); //get All Clients from Cleint ID
              })
            );
            const siteCondition = siteID
              ? syncExpressionsByUserType.push(
                  syncExpression(Site, () => {
                    return (test) => test.id("eq", siteID); // If siteID is present, match by siteID
                  })
                )
              : syncExpressionsByUserType.push(
                  syncExpression(Site, () => {
                    return (test) => test.clientID("eq", clientIDInLocalStorage.get()); // Otherwise, match by clientID from local storage
                  })
                );
          }

          if (CONFIG.isWhiteLabel && CONFIG.clientID) {
            syncExpressionsByUserType.push(
              syncExpression(Lab, () => {
                return (test) => test.id("eq", CONFIG.whiteLabelID); //get All Labs from Lab ID
              })
            );
            syncExpressionsByUserType.push(
              syncExpression(GeneralSettingType, () => {
                return (test) => test.clientID("eq", clientIDInLocalStorage.get());
              })
            );
            syncExpressionsByUserType.push(
              syncExpression(Client, () => {
                return (test) => test.id("eq", CONFIG.clientID); //get All Clients from Client ID
              })
            );
            syncExpressionsByUserType.push(
              syncExpression(Site, () => {
                return (test) => test.clientID("eq", CONFIG.clientID); //get All Clients from Client ID
              })
            );
            syncExpressionsByUserType.push(
              syncExpression(UploadRequestMain, () => {
                return (test) => test.and((test) => [test.createdBy("eq", user?.sub)]); //get All  UploadRequestMain from user ID
              })
            );
            syncExpressionsByUserType.push(
              syncExpression(UploadRequest, () => {
                return (test) => test.createdBy("eq", user?.sub); //get All UploadRequest from User ID
              })
            );
            syncExpressionsByUserType.push(
              syncExpression(Documents, () => {
                return (test) => test.clientID("eq", clientIDInLocalStorage.get());
              })
            );
            syncExpressionsByUserType.push(
              syncExpression(LencoBarcodes, () => {
                return (test) => test.clientID("eq", clientIDInLocalStorage.get());
              })
            );
          }

          if (user.isLab()) {
            let connectedIDValue = connectedIDInLocalStorage.get();
            connectedIDValue = connectedIDValue || user["custom:connectedID"];
            syncExpressionsByUserType.push(
              syncExpression(Test, () => {
                return (test) => test.labID("eq", connectedIDValue).test_type("eq", "PCR").createdAt("ge", date); //get All Tests from Lab ID
              })
            );
          }
          if (user.isSite()) {
            let connectedIDValue = connectedIDInLocalStorage.get();
            connectedIDValue = connectedIDValue || user["custom:connectedID"];
            syncExpressionsByUserType.push(
              syncExpression(Test, () => {
                return (test) => test.siteID("eq", connectedIDValue).createdAt("ge", date); //get All Tests from Lab ID
              })
            );
            syncExpressionsByUserType.push(
              syncExpression(LencoBarcodes, () => {
                return (test) => test.id("eq", "123");
              })
            );
          }

          DataStore.configure({
            maxRecordsToSync: 90000,
            syncPageSize: 2000,
            syncExpressions: syncExpressionsByUserType,
          });
          DataStore.start();
          dispatch(fetchCompanySetting({ noShowLoader: true }));
          setUser(user);
          handleStart();
        } else {
          setLoading(false);
          setShowAlert(true);
        }
      } else {
        setLoading(false);
        console.log("push to login");
        history.push("/login");
      }
    };
    getUSer()
      .then((res) => {
        showSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateSite)).subscribe({
          next: ({
            value: {
              data: { onUpdateSite },
            },
            value,
          }) => {
            onSubscriptionUpdateSites(onUpdateSite, "UPDATE");
          },
          error: (error) => console.warn(error),
        });

        showCreateSubscription = API.graphql(graphqlOperation(subscriptions.onCreateSite)).subscribe({
          next: ({
            value: {
              data: { onCreateSite },
            },
            value,
          }) => {
            onSubscriptionUpdateSites(onCreateSite, "INSERT");
          },
          error: (error) => console.warn(error),
        });

        if (CONFIG.clientID) {
          uploadRequestMainGQLSubscription = API.graphql(
            graphqlOperation(subscriptions.onUpdateUploadRequestMain)
          ).subscribe({
            next: ({
              value: {
                data: { onUpdateUploadRequestMain },
              },
              value,
            }) => {
              const { createdBy, clientID, siteID, filesStatus } = onUpdateUploadRequestMain;

              if (!onUpdateUploadRequestMain || createdBy !== userRef.current?.sub) return;

              const isValidClient = clientID === CONFIG.clientID || !CONFIG.clientID;
              const isValidSite = siteID === CONFIG.siteID || !CONFIG.siteID;

              if (!isValidClient || !isValidSite) return;

              realTimeStatus(
                { ...onUpdateUploadRequestMain, filesStatus: filesStatus ? JSON.parse(filesStatus) : null },
                CRUD_TYPE.UPDATE,
                true
              );
            },
            error: (error) => console.warn(error),
          });
        }

        uploadRequestGQLSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateUploadRequest)).subscribe({
          next: ({
            value: {
              data: { onUpdateUploadRequest },
            },
            value,
          }) => {
            if (!onUpdateUploadRequest && onUpdateUploadRequest.clientID !== CONFIG.clientID && !CONFIG.siteID) {
              return;
            }
            if (!onUpdateUploadRequest && onUpdateUploadRequest.siteID !== CONFIG.siteID && !CONFIG.siteID) {
              return;
            }
            if (onUpdateUploadRequest.createdBy === userRef.current?.sub)
              realTimeStatus(
                { ...onUpdateUploadRequest, data: JSON.parse(onUpdateUploadRequest.data) },
                CRUD_TYPE.UPDATE
              );
          },
          error: (error) => console.warn(error),
        });
        orderUpdateGQLSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateOrder)).subscribe({
          next: ({
            value: {
              data: { onUpdateOrder },
            },
            value,
          }) => {
            setOrderObject("UPDATE", onUpdateOrder);
          },
          error: (error) => console.warn(error),
        });
        orderCreateGQLSubscription = API.graphql(graphqlOperation(subscriptions.onCreateOrder)).subscribe({
          next: ({
            value: {
              data: { onCreateOrder },
            },
            value,
          }) => {
            setOrderObject("INSERT", onCreateOrder);
          },
          error: (error) => console.warn(error),
        });
        createRequestGQLSubscription = API.graphql(graphqlOperation(subscriptions.onCreateUploadRequest)).subscribe({
          next: ({
            value: {
              data: { onCreateUploadRequest },
            },
            value,
          }) => {
            if (!onCreateUploadRequest && onCreateUploadRequest.clientID !== CONFIG.clientID && !CONFIG.siteID) {
              return;
            }
            if (!onCreateUploadRequest && onCreateUploadRequest.siteID !== CONFIG.siteID && !CONFIG.siteID) {
              return;
            }
            if (onCreateUploadRequest.createdBy === userRef.current?.sub)
              realTimeStatus(
                { ...onCreateUploadRequest, data: JSON.parse(onCreateUploadRequest.data) },
                CRUD_TYPE.INSERT
              );
          },
          error: (error) => console.warn(error),
        });
        clientSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateClient)).subscribe({
          next: ({
            value: {
              data: { onUpdateClient: clientData },
            },
            value,
          }) => {
            const { uploadFileStatus } = clientData;
            if (!uploadFileStatus) return;
            const fileStatus = JSON.parse(uploadFileStatus);

            if (
              !(userRef.current.roles.includes("Employers") && clientData.id === CONFIG.clientID) &&
              fileStatus.createdBy !== userRef.current?.sub
            ) {
              return;
            }

            if (fileStatus && fileStatus.createdBy === userRef.current?.sub) {
              // getPatientResultFromStorage(fileStatus);
            }
          },
          error: (error) => console.warn(error),
        });

        testUpdateSubscription = API.graphql(graphqlOperation(subscriptions.onCreateTest)).subscribe({
          next: ({
            value: {
              data: { onCreateTest },
            },
            value,
          }) => {
            onSubscriptionUpdateTestData(onCreateTest, "INSERT");
          },
          error: (error) => console.warn(error),
        });
        apiSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateTest)).subscribe({
          next: ({ value: { data }, value }) => {
            let model = data.onUpdateTest;
            onSubscriptionUpdateTestData(model, "UPDATE");
          },
          error: (error) => console.warn(error),
        });
        patientSubscriptionUpdate = API.graphql(graphqlOperation(subscriptions.onUpdateEmployee)).subscribe({
          next: ({ value: { data }, value }) => {
            onSubscriptionUpdateEmployeeData(data.onUpdateEmployee, "UPDATE");
          },
          error: (error) => console.warn(error),
        });
        patientSubscriptionCreate = API.graphql(graphqlOperation(subscriptions.onCreateEmployee)).subscribe({
          next: ({ value: { data }, value }) => {
            onSubscriptionUpdateEmployeeData(data.onCreateEmployee, "INSERT");
          },
          error: (error) => console.warn(error),
        });
        patientSubscriptionDelete = API.graphql(graphqlOperation(subscriptions.onDeleteEmployee)).subscribe({
          next: ({ value: { data }, value }) => {
            onSubscriptionUpdateEmployeeData(data.onDeleteEmployee, "DELETE");
          },
          error: (error) => console.warn(error),
        });
      })
      .catch((err) => {
        console.log("Error:-", err);
      });
    return () => {
      if (apiSubscription) apiSubscription.unsubscribe();
      if (patientSubscriptionDelete) patientSubscriptionDelete.unsubscribe();
      if (patientSubscriptionCreate) patientSubscriptionCreate.unsubscribe();
      if (patientSubscriptionUpdate) patientSubscriptionUpdate.unsubscribe();
      if (showSubscription) showSubscription.unsubscribe();
      if (showCreateSubscription) showCreateSubscription.unsubscribe();
      if (clientSubscription) clientSubscription.unsubscribe();
      if (orderUpdateGQLSubscription) orderUpdateGQLSubscription.unsubscribe();
      if (orderCreateGQLSubscription) orderCreateGQLSubscription.unsubscribe();
      if (uploadRequestGQLSubscription) uploadRequestGQLSubscription.unsubscribe();
      if (createRequestGQLSubscription) createRequestGQLSubscription.unsubscribe();
      if (uploadRequestMainGQLSubscription) uploadRequestMainGQLSubscription.unsubscribe();
      if (testUpdateSubscription) testUpdateSubscription.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const contextSwitch = (role) => {
    const generalObj = {
      user,
      labs,
      sites,
      clients,
      totalTests,
      eventOrder,
      locationObject,
      userLabName,
      setIsProcessing: (val) => setIsProcessing(val),
      showEligibilityDetails: (obj) => checkEligibilityDetails(obj),
      setNotificationList: (val) => setNotificationList((prev) => [...prev, val]),
      printBarcode: (test) => printBarcode(test),
      showLogs: (test) => setShowTestLogModal(test),
      sendEmailToCrew: (tests) => sendResultOnEmail(tests),
      showTestScheduleModal: (employees, isUploadCall) => {
        setTestScheduleModal(employees);
        setOpenTestScheduleModal(isUploadCall);
      },
      openPatientTestViewModal: (selectedRow) => setPatientTestViewModal(selectedRow),
      showTestResult: (test) => setResultView(test),
      showRequisitionForm: (form) => setRequisitionView(form),
      showUpComingSchedule: () => setOpenUpComingSchedule(true),
      showPatient: (emp) => setShowPatientModal(emp),
      patientObject,
      testFilter,
      setTestFilter: (filter) => setTestFilter(filter),
      showSuccessMessage: (message) => setSuccessMessageText(message),
      showSuccessBottomMessage: (message) => setSuccessBottomMessageText(message),
      showErrorMessage: (message) => setErrorMessageText(message),
      getEmployeeID: (id) => getEmployeeByID(id),
      parseResult: (test) => parseResult(test),
      parseTestResultForPDF: (test) => parseTestResultForPDF(test),
      getLab: (test) => getLab(test),
      getLabName: (id) => getLabName(id),
      getClientName: (id) => getClientName(id),
      getSiteName: (id) => getSiteName(id),
      openNoteModal: (obj) => setNoteObject(obj),
      showLoadOrderModal: (obj) => setLoadOrderModal(true),
      showOrderModal: (obj) => setShowOrderModal(obj),
      createTestFromOrder: (obj) => setShowOrderTestModal(obj),
      downloadRequisition: (obj) => downloadRequisition(obj),
      sendToLab: (tests, checkboxes) => handleExportLabGen(tests, checkboxes),
      resetSites: (params) => fetchSites(params),
    };
    const adminCommon = {
      users,
      resetUsers: (id) => fetchUserFromLocalDB(id),
      testObject,
      openDeleteConfirmation: (tests, afterDeletedTest) => setOpenDeleteConfirmation({ tests, afterDeletedTest }),
      resetLabs: () => fetchLabs(),
      resetClients: () => fetchClients(),
    };
    if (user?.isAdmin()) {
      return {
        ...generalObj,
        ...adminCommon,
        isShowUpdated,
        pendingTests,
        invalidTests,
        resetPendingTests: (ids) => fetchPendingTests(ids),
        // resetInvalidTests: (ids) => fetchInvalidTests(ids),
        completedTests,
        unprocessedTests,
        unSubmittedTests,
        adminTotalTests,
        externalTest,
        isSuperAdmin: () => isSuperAdmin(),
        resetUnprocessedTests: () => fetchUnprocessedTests(),
        resetUnSubmittedTests: () => fetchUnSubmittedTests(),
        resetAllTests: () => refreshDataOnSubscribe(),
        resetTotalTest: () => fetchAllAdminTests(),
        resetExternalTest: () => fetchExternalTests(),
        getSiteTests: (id) => getSiteTests(id),
        getLabTests: (id) => getLabTests(id),
        getClientTests: (id) => getClientTests(id),
        admins,
        newTests,
        deleteTests: (tests) => deleteTests(tests),
      };
    }

    if (user?.isLab()) {
      return {
        ...generalObj,
        ...adminCommon,
        eventObject,
        userLab,
        userLabName,
        admins,
        newTests,
        pendingTests,
        resetPendingTests: (ids) => fetchPendingTests(ids),
        resetNewTests: () => fetchNewTests(),
      };
    }

    if (user?.isSite()) {
      return {
        ...generalObj,
        eventObject,
        externalTest,
        userSiteName,
        resetHSTests: () => fetchSiteTotalTest(),
        resetExternalTest: () => fetchExternalTests(),
      };
    }

    if (user?.isClient()) {
      return {
        ...generalObj,
        sites,
        labs,
        clients,
        resetHSTests: () => fetchClientTotalTest(),
      };
    }
  };
  const setLabRoutes = () => {
    let filterRoutes;
    try {
      filterRoutes = whiteLabelLabRoutes.filter((f) => permission[WHITE_LABEL_ROTUES[f.path]]?.access);

      if (permission?.completedTests?.access || permission?.pendingResults?.access) {
        const testRoute = {
          path: "/test/:testId",
          name: "Test",
          icon: "nc-icon nc-circle-09",
          component: TestDetails, // Replace with your actual TestDetails component
          layout: "/admin",
          redirect: true,
        };
        // Check if it's already in filterRoutes to avoid duplicates
        addRouteIfNotExists(testRoute, filterRoutes);
      }
    } catch (error) {
      filterRoutes = whiteLabelLabRoutes; // Provide default filtered routes
    }
    if (!filterRoutes) {
      filterRoutes = whiteLabelLabRoutes;
    }
    const type = medFlowInLocalStorage.get() || "A";
    let routeList = labRoutes;
    if (type === "LT") {
      routeList = LabTechRoutes;
    } else if (CONFIG.isWhiteLabel) {
      if (user?.phone_number === "+18888888888") {
        routeList = filterRoutes;
      } else {
        routeList = filterRoutes.filter((r) => r.name !== "Archived Tests" && r.name !== "Archived Orders");
      }
    }
    setUserRoutes(routeList);
  };

  const isSuperAdmin = () => {
    if (user?.isAdmin()) {
      return user?.phone_number === "+18888888888" || user?.phone_number === "+12222222222";
    }
    return false;
  };

  const getClientTests = (id) => {
    const clientObj = adminTotalTests.filter((t) => t.clientID === id);
    return clientObj.length;
  };

  const getSiteTests = (id) => {
    const siteObj = adminTotalTests.filter((t) => t.siteID === id);
    return siteObj.length;
  };
  const getLabTests = (id) => {
    const labObj = adminTotalTests.filter((t) => t.labID === id);
    return labObj.length;
  };

  const getSiteName = (id) => {
    const siteObj = sites.find((site) => site.id === id);
    return siteObj ? siteObj.name : "NA";
  };

  const getLabName = (id) => {
    const labObj = labs.find((lab) => lab.id === id);
    return labObj ? labObj.name : "NA";
  };

  const getLab = async (test) => {
    let lab = labs.find((lab) => lab.id === test.labID);
    if (!lab) {
      lab = await localApi.getLabByID(test?.labID);
    }

    if (lab && "logo" in lab && lab.logo) {
      const path = await Storage.get(lab.logo);
      return { ...lab, logoSrc: path };
    }
    return lab;
  };

  const deleteTests = async (tests) => {
    if (tests.length === 0) return;

    await GQAPI.deleteWrongTest(tests.map((t) => t.id));

    setSuccessMessageText(`${tests.length} test${tests.length === 1 ? " was" : "s were"} Archived!`);
  };

  const sendResultOnEmail = async (param, slug) => {
    if (param.length === 0) return;
    setLoading(true);
    let ttlEmails = param.length;
    try {
      await GQAPI.sendEmailToPendingRelease(
        param.map((p) => {
          return { ...p, userID: user?.sub, userName: user?.name };
        })
      );
      const params = { ids: param, userID: user?.sub, userName: user?.name, slug: "Email" };
      // api.addTestLogs(params);

      setLoading(false);
      setSuccessMessageText(
        `Email has been successfully sent to ${ttlEmails} ${CONFIG.isWhiteLabel ? "Patient" : "Crew"}`
      );
    } catch (error) {
      console.log("error:-", error.message);
      setLoading(false);
      setErrorMessageText("Fail to send the Email");
    }
  };

  const triggerFirebaseListener = async (updateSelectedTestResult, type) => {
    let firestoreName = "PCR";
    if (type === "rapid pcr") firestoreName = "Other";
    else if (type === "molecular") firestoreName = "Molecular";

    const tests = updateSelectedTestResult.filter((t) => t.test_type.toLowerCase() === type);
    if (tests.length > 0) {
      const testCollection = collection(db, firestoreName);
      addDoc(testCollection, {
        date: new Date().toISOString(),
      });
    }
  };

  const handleExportLabGen = async (filteredTests, checkboxes) => {
    setLoading(true);
    try {
      const tests = filteredTests.filter((f) => checkboxes.includes(f.id));

      if (tests.length === 0) {
        setLoading(false);
        setErrorMessageText("No record to be proceed");
        return;
      }

      const { testPushed, data } = await localApi.formatBiolabGenCSV(tests);
      await localApi.saveFileSendToLab(
        `${moment().format("DD_MM_YYYY_HH_mm_ss")}_send_to_lab_${CONFIG.envType}.csv`,
        tests.map((t) => {
          return { ...t, status: "Sent", submittedBy: user?.sub, submittedByName: user?.name };
        })
      );
      if (testPushed) {
        setSuccessMessageText(`${tests.length} test${tests.length > 0 ? "s" : ""} send to lab`);
      } else {
        setErrorMessageText(`Fail to send to Lab`);
      }
      await downloadDataAsCSV(data, `labgen_orders_${moment().format("MM_DD_YYYY")}.csv`);
      setLoading(false);
    } catch (ex) {
      console.log("Ex", ex);
      setLoading(false);
      setErrorMessageText(`Fail to send to Lab`);
    }
  };

  const adminListener = () => {
    const hubListener = Hub.listen("datastore", (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        console.log("EVENT READY");

        // fetchPendingTests();
        fetchAdmins();
        // fetchInvalidTests();
        fetchClients();
        fetchSites();
        fetchLabs();
        // fetchExternalTests();
        Hub.remove("datastore", hubListener);
        setLoading(false);
        dispatch(fetchPayerAsync());
        if (mainPanel.current) mainPanel.current.scrollTop = 0;
      }
    });
  };

  const labListener = () => {
    const hubListener = Hub.listen("datastore", (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        setLabRoutes();

        if (CONFIG.isWhiteLabel) {
          fetchClients();
          //  fetchPendingTests();
          fetchUploadRequest();
          fetchSites();
        }
        fetchLabs();
        fetchNewTests();
        setLoading(false);
        Hub.remove("datastore", hubListener);
        dispatch(fetchPayerAsync());
      }
    });
  };

  const siteListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;

      if (event === "ready") {
        fetchLabs();
        setSites(await fetchSitesByUser(connectedIDInLocalStorage.get()));
        fetchClients();
        // fetchExternalTests();
        fetchUploadRequest();
        // mainPanel.current.scrollTop = 0;
        Hub.remove("datastore", hubListener);
        setLoading(false);
        dispatch(fetchPayerAsync());
      }
    });
  };

  const clientListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchLabs();
        setSites(await fetchSitesByClient(user.clientID));
        fetchUploadRequest();
        fetchClients();
        fetchClientTotalTest();
        setUserRoutes(siteRoutes);
        setLoading(false);
        // mainPanel.current.scrollTop = 0;
        Hub.remove("datastore", hubListener);
        dispatch(fetchPayerAsync());
      }
    });
  };

  const fetchSiteTotalTest = () => {
    if (user?.isSite() && sites.length > 0) {
      findTotalTestsBySites(sites);
    }
  };

  const fetchClientTotalTest = () => {
    if (user?.isClient() && sites.length > 0) {
      findTotalTestsBySites(sites);
    }
  };

  React.useEffect(() => {
    user?.isAdmin() && adminListener();
    user?.isLab() && labListener();
    user?.isSite() && siteListener();
    user?.isClient() && clientListener();
  }, [user]);

  React.useEffect(() => {
    user?.isClient() && fetchClientTotalTest();
  }, [sites]);

  const updateOrderFilesStatus = (index, status, respFile) => {
    setOrderFiles((prev) => {
      if (!prev || Object.keys(prev).length === 0) return {};
      const updatedStatus = [...prev.uploadStatus];
      updatedStatus[index] = { ...updatedStatus[index], status: status, fileName: respFile };
      return { ...prev, uploadStatus: updatedStatus };
    });
  };

  const pushFileOnServer = async (name, file, index) => {
    try {
      // await handleFileUpload(name, file);

      const ext = FILE_REPRESENT_TYPES_MAP.get(file.type);
      const respFile = await localApi.saveOrderFileIntoBucket({ name, fileContent: file, ext: ext });

      if (respFile) {
        updateOrderFilesStatus(index, "done", respFile);
      }
    } catch (err) {
      updateOrderFilesStatus(index, "fail", null);
    }
  };

  const handleOnSaveFiles = async (filesData) => {
    setLoadOrderModal(false);
    try {
      const { files, selectedFileNames } = filesData;
      selectedFileNames.forEach((f, index) => {
        pushFileOnServer(f, files[index], index);
      });
    } catch (err) {
      console.log("Error:-", err);
      setErrorMessageText("Fail to Upload the Files");
    }
  };

  const handleCancelUploadRequest = async (id) => {
    try {
      let requestID = id;
      if (!orderFiles || Object.keys(orderFiles).length === 0) {
        requestID = notificationList[0]?.id;
      }

      if (requestID) {
        setNotificationList((prev) => prev.filter((f) => f.id !== requestID));
      } else {
        setOrderFiles({});
        setUploadFileStatus(null);
        return;
      }
      setUploadPatientResult([]);
      setUploadFileStatus(null);
      await api.deleteUploadRequest(requestID, user);
    } catch (ex) {
      console.log("Error-", ex);
    }
  };
  const onConfirmationModal = (isConfirm) => {
    try {
      setOpenConfirmation({ isShow: false });
      if (!isConfirm) return;
      handleCancelUploadRequest(openConfirmation.value || "");
    } catch (error) {
      console.log("Error:-", error.message);
    }
  };

  const isCompleteUploadRequest = (fileStatus) => {
    if (!fileStatus || fileStatus.length === 0) return false;
    const listFile = [...fileStatus];
    return listFile.every((e) => e.status.includes("Parsed"));
  };

  const handleCancelRequests = (data, e) => {
    if (e) e.stopPropagation();
    // If Status bar is Completed then no need to be show Confirmation
    if (data && isCompleteUploadRequest(data.filesStatus)) {
      handleCancelUploadRequest(data.id);
      return;
    }
    setOpenConfirmation({
      isShow: true,
      message: `Are you sure, you want to cancel upload?`,
      actionType: "confirmation",
      title: "Cancel Upload",
      value: data?.id,
    });
  };

  const handleCloseResultModal = () => {
    const testsList = showDetailModal ? [detailTestList[0]] : showSimpleModal ? simpleTestList : [];

    dispatch(setTestIdsToRemove(testsList.map((item) => item.id)));

    dispatch(resetStateResultState());
  };

  const getDefaultView = useMemo(() => {
    if (permission?.orders?.access) return <OrdersView />;
    if (permission?.employee?.access) return <PatientView />;
    if (permission?.pendingResults?.access && !user?.isSite()) return <UnprocessedTestsApi />;
    if (permission?.completedTests?.access && !user?.isSite()) return <TestListAdminApi />;
    if (permission?.settings?.access && !user?.isSite()) return <LabTestTypesSetting />;
    if (permission?.archiveTest && !user?.isSite()) return <DeleteTestApi />;
    if (permission?.archivedOrders && !user?.isSite()) return <OrdersView />;
    return null; // Return null if none of the conditions match
  }, [permission]); // Dependency array ensures useMemo runs only when permissions change

  useEffect(() => {
    const checkPermissionAndRedirect = () => {
      let currentPath = location.pathname;
      // Remove '/admin' from the beginning of currentPath
      if (currentPath.startsWith("/admin")) {
        currentPath = currentPath.substring(6); // Remove '/admin' prefix
      }
      const permissionKey = WHITE_LABEL_ROTUES[currentPath];
      // Check if permission is defined and not an empty object
      const isPermissionDefined = permission && Object.keys(permission).length > 0;
      if (isPermissionDefined) {
        // Check if permissionKey exists and user has access
        if (permissionKey && !permission[permissionKey]?.access) {
          history.push("/admin");
        }
        // If permissionKey doesn't exist, redirect to admin
        else if (!permissionKey) {
          history.push("/admin");
        }
      }
    };

    checkPermissionAndRedirect();
  }, [permission]);

  return (
    <>
      <div className="wrapper">
        {showAlert && (
          <SweetAlert
            show={showAlert}
            error
            title="Error"
            onConfirm={async () => {
              await Auth.signOut();
              window.location.reload();
            }}
          >
            Your account does not have access to the Med Flow portal
          </SweetAlert>
        )}
        {/* successBottomMessageText */}
        {successMessageText && (
          <SweetAlert
            show={successMessageText ? true : false}
            success
            title="Success"
            onConfirm={() => setSuccessMessageText("")}
          >
            {successMessageText}
          </SweetAlert>
        )}
        {successBottomMessageText && (
          <ShowAlertMessage
            message={successBottomMessageText}
            success
            show={successBottomMessageText ? true : false}
            handleClose={() => setSuccessBottomMessageText("")}
          />
        )}
        {errorMessageText && (
          <ShowAlertMessage
            message={errorMessageText}
            error
            show={errorMessageText ? true : false}
            handleClose={() => setErrorMessageText("")}
          />
        )}
        {messageObj.text && (
          <ShowAlertMessage
            message={messageObj.text}
            title={messageObj?.title}
            handleClose={() => dispatch(showMessage())}
            {...{ [messageObj.mode]: true }}
          />
        )}
        <Sidebar
          color={color}
          image={""}
          routes={userRoutes}
          user={user}
          pendingTests={pendingTests}
          invalidTests={invalidTests}
          testFilter={testFilter}
          setTestFilter={setTestFilter}
        />
        {/* comment back in to enable log out timer!!!!!!! */}
        {/* <IdleTimer
            crossTab={{
              emitOnAllTabs: false,
            }}
            startOnMount={false}
            startManually={true}
          /> */}

        {associatedPanelData && (
          <TestAssociatedViewModal
            handleClose={() => dispatch(emptyAssociatedPanel(null))}
            associatedPanels={associatedPanelData?.targetRanges}
            title={associatedPanelData?.name || ""}
          />
        )}

        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar
            routes={userRoutes}
            completedTests={completedTests}
            pendingTests={pendingTests}
            role={role || user?.roles[0]}
            user={user}
            userLabName={userLabName}
            userSiteName={userSiteName}
            isProcessing={isProcessing}
            notificationList={notificationList}
            orderFiles={orderFiles}
            setNotificationList={setNotificationList}
            onCancelRequest={handleCancelUploadRequest}
            handleSelect={handleSelect}
            setLoading={setLoading}
            handleCancelRequests={handleCancelRequests}
            downloadIndicator={downloadIndicator}
          />
          {!loading ? (
            <div className="content content-body">
              <AppContext.Provider value={contextSwitch(user?.roles[0])}>
                <Switch>{getRoutes(userRoutes)}</Switch>
                {window.location.pathname === "/admin" && CONFIG.isWhiteLabel && user?.isSite() && (
                  // <AdminHomeApi completedTests={adminTotalTests} />
                  <OrderView />
                )}
                {
                  window.location.pathname === "/admin" &&
                    CONFIG.isWhiteLabel &&
                    !user?.isSite() &&
                    // <AdminHomeApi completedTests={adminTotalTests} />
                    // <TestListAdminApi />
                    getDefaultView
                  // <OrdersView />
                }
                {window.location.pathname === "/admin" &&
                  user?.isAdmin() &&
                  !clientIDInLocalStorage.get() &&
                  !CONFIG.isWhiteLabel && (
                    // <AdminHomeApi completedTests={adminTotalTests} />
                    <AdminDashboard />
                  )}

                {window.location.pathname === "/admin" && user?.isClient() && (
                  //  <HealthAndSafetyHome sites={sites} />
                  <ClientsDashboard sites={sites} />
                )}
                {window.location.pathname === "/admin" && user?.isLab() && !CONFIG.isWhiteLabel && (
                  // <TableListApi />
                  <LabDashboard lab={labs[0]} />
                )}

                {showDetailModal && (
                  <AssignResultsTestModal
                    handleClose={handleCloseResultModal}
                    selectedTest={showDetailModal && detailTestList[0].detailTest}
                    testTypeResult={showDetailModal && detailTestList[0].testResultTypeData}
                  />
                )}
                {showPanalModal && <PanelResultModal panelTests={panelTests} />}

                {showSimpleModal && (
                  <ChangeResultModal
                    handleClose={handleCloseResultModal}
                    title="Result Tests"
                    tests={simpleTestList}
                    // appContext={contextSwitch(user?.roles[0])}
                  />
                )}
                {testForResult && <ResultUploadModal test={testForResult} />}
                {employeeForTests?.length > 0 && <TestSelectionModal employeeForTests={employeeForTests} />}
              </AppContext.Provider>
            </div>
          ) : (
            <Loader />
          )}
          {/* <Footer /> */}
        </div>
      </div>
      <AppContext.Provider value={contextSwitch(user?.roles[0])}>
        {openDeleteConfirmation && (
          <ConfirmationModal
            show={openDeleteConfirmation}
            title="Archive Test"
            message={`Are you sure, you want to Archive ${openDeleteConfirmation.tests.length} Test${
              openDeleteConfirmation.tests.length > 1 ? "s" : ""
            }`}
            handleConfirm={handleDeleteConfirm}
          />
        )}

        {selectedTestForPrint && (
          <ShowPrintOptions
            printBarcode={printBarcode}
            tests={selectedTestForPrint}
            handleClose={() => dispatch(setTestPrintView(null))}
          ></ShowPrintOptions>
        )}

        {showTestLogModal && (
          <TestLogsModal
            test={showTestLogModal}
            setSuccessMessageText={setSuccessMessageText}
            handleClose={() => {
              setShowTestLogModal(null);
            }}
          ></TestLogsModal>
        )}

        {showPatientModal && (
          <PatientProfileModal
            patient={showPatientModal}
            handleClose={(obj) => {
              // if (obj && obj.requestID && uploadPatientResult.length > 0) {
              //   setUploadPatientResult(
              //     uploadPatientResult.map((m, i) => {
              //       if (m.id === obj.requestID) {
              //         return {
              //           ...m,
              //           status: "pass",
              //           data: { ...m.data, patientCreated: "pass", orderCreated: "pass", empID: obj.id },
              //         };
              //       }
              //       return { ...m };
              //     })
              //   );
              // }
              setShowPatientModal(null);
            }}
            appContext={contextSwitch(user?.roles[0])}
          />
        )}

        {showOrderModal && (
          <OrderTestModal
            order={showOrderModal}
            handleCloseCreateTest={() => {
              setShowOrderModal(null);
            }}
            clients={clients}
            sites={sites}
            labs={labs}
            appContext={contextSwitch(user?.roles[0])}
          />
        )}

        {showOrderTestModal && (
          <CreateOrderTest
            item={showOrderTestModal}
            userLabName={userLabName}
            handleCloseCreateTest={(message) => {
              setShowOrderTestModal(null);
              if (message) {
                setSuccessBottomMessageText(message);
              }
            }}
            appContext={contextSwitch(user?.roles[0])}
          />
        )}

        {(openTestScheduleModal || testScheduleModal) && (
          <TestScheduleModal
            employees={testScheduleModal}
            uploadCallType={openTestScheduleModal}
            user={user}
            showErrorMessage={setErrorMessageText}
            showSuccessMessage={setSuccessMessageText}
            appContext={contextSwitch(user?.roles[0])}
            handleClose={(val) => {
              if (val && val.id) {
                setNotificationList((prev) => [...prev, val]);
              }
              setTestScheduleModal(null);
              setOpenTestScheduleModal(null);
            }}
          ></TestScheduleModal>
        )}

        {uploadPatientResult.length > 0 && (
          <PatientImportErrorModal
            data={uploadPatientResult}
            files={notificationList[0]}
            onCompleteLoad={handleCancelUploadRequest}
            handleClose={() => setUploadPatientResult([])}
            appContext={contextSwitch(user?.roles[0])}
            title={"Test Scheduling Upload Management"}
          />
        )}

        {uploadFileStatus && uploadFileStatus !== null && (
          <ImportFileStatusModal
            data={uploadFileStatus}
            handleClose={() => {
              setUploadFileStatus(null);
            }}
            handleCancelAll={handleCancelUploadRequest}
            handleCancelRequests={handleCancelRequests}
          />
        )}
        {patientTestViewModal !== null && (
          <TestDoneModal
            patient={patientTestViewModal.patient}
            order={patientTestViewModal.order}
            uiType={patientTestViewModal?.uiType}
            showResult={setResultView}
            handleClose={() => setPatientTestViewModal(null)}
          />
        )}
        {resultView !== null && empId && (
          <ResutlViewModal
            test={resultView}
            appContextView={{ getLab: getLab }}
            handleClose={() => setResultView(null)}
            detailEmpId={empId}
          />
        )}

        {requisitionView && (
          <RequisitionFormViewModal requisitionView={requisitionView} handleClose={() => setRequisitionView(null)} />
        )}

        {openInsuranceModal && (
          <InsuranceModal
            cssClass="seperateModal"
            handleClose={() => {
              dispatch(setOpenInsuranceModal(false));
              dispatch(setSelectedInsurance(null));
            }}
          />
        )}
        {openUpComingSchedule && (
          <UpComingScheduleModal
            employees={testScheduleModal}
            uploadCallType={openTestScheduleModal}
            user={user}
            showErrorMessage={setErrorMessageText}
            showSuccessMessage={setSuccessMessageText}
            handleClose={() => {
              setOpenUpComingSchedule(false);
            }}
          />
        )}
        {noteObject && <TestNoteModal data={noteObject} handleClose={() => setNoteObject(null)} user={user} />}
        {eligibilityDetail && eligibilityDetail !== null && (
          <EligibilityDetailsModal
            handleClose={() => setEligibilityDetail(null)}
            data={eligibilityDetail}
            cssClass="seperateModal"
          />
        )}
        {showGenericModal && <GenericPanelTestView data={genericData} />}

        {showGenericPathogenModal && <GenericPathogenView data={genericPathogenData} />}

        {openLoadOrdersModal && (
          <LoadOrdersModal
            setOrderFiles={setOrderFiles}
            onSaveFile={handleOnSaveFiles}
            handleClose={() => setLoadOrderModal(false)}
            sites={sites}
          />
        )}

        {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}

        {/* {notificationList && notificationList.length > 0 && (
        <NotificationModal notificationList={notificationList} handleSelect={handleSelect} />
      )} */}
        {isGlobalLoader && <Loader />}
        {openConfirmation.isShow && (
          <ConfirmationModal
            show={openConfirmation.isShow}
            actionType={openConfirmation.actionType}
            title={openConfirmation.title}
            message={openConfirmation.message}
            handleConfirm={onConfirmationModal}
            cssClass="seperateModal"
          />
        )}
        <DocumentPreviewModal
          isModalOpen={showPdf}
          setIsModalOpen={() => dispatch(hideResultPdf())}
          fileUrl={resultFileUrl}
          fileType={resultFileType}
          title={resultPdfTitle}
          // title={`Test Results - ${docName ? docName : ""}`}
        />
        <GlobalActionsComponent />
      </AppContext.Provider>
      <IdleModal
        showIdleModal={showIdleModal}
        animation={true}
        handleLogOut={handleLogOut}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}

export default AdminNew;
