/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLab = /* GraphQL */ `
  subscription OnCreateLab {
    onCreateLab {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      fax_number
      user_id
      contact_email
      tubes_provided
      lab_director
      country
      referredDoctor
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      custom_notes
      invalid_notes
      indeterminate_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      logourl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateLab = /* GraphQL */ `
  subscription OnUpdateLab {
    onUpdateLab {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      fax_number
      user_id
      contact_email
      tubes_provided
      lab_director
      country
      referredDoctor
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      custom_notes
      invalid_notes
      indeterminate_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      logourl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteLab = /* GraphQL */ `
  subscription OnDeleteLab {
    onDeleteLab {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      fax_number
      user_id
      contact_email
      tubes_provided
      lab_director
      country
      referredDoctor
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      custom_notes
      invalid_notes
      indeterminate_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      logourl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSite = /* GraphQL */ `
  subscription OnCreateSite {
    onCreateSite {
      id
      name
      clientID
      contact_name
      contactName
      contact_phone
      phone_number
      email
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      isInsurance
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isActive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      companyID
      sendIdentification
      isAutoSubmitClaim
      sendExternalRequisition
      sendPrescription
      sendAuthorization
      sendOtherDocuments
      startBarCode
      uploadFileStatus
      hippa
      hippaFile
      resultTemplate
      isTesting
      street
      city
      state
      zipcode
      countryCode
      country
      setting
      defaultProvider
      practiceID
      labLocalID
      fax
      contact_fax
      discount
      commission
      totalOrders
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      note
      createdBy
      createdByName
      updatedByName
      siteType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateSite = /* GraphQL */ `
  subscription OnUpdateSite {
    onUpdateSite {
      id
      name
      clientID
      contact_name
      contactName
      contact_phone
      phone_number
      email
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      isInsurance
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isActive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      companyID
      sendIdentification
      isAutoSubmitClaim
      sendExternalRequisition
      sendPrescription
      sendAuthorization
      sendOtherDocuments
      startBarCode
      uploadFileStatus
      hippa
      hippaFile
      resultTemplate
      isTesting
      street
      city
      state
      zipcode
      countryCode
      country
      setting
      defaultProvider
      practiceID
      labLocalID
      fax
      contact_fax
      discount
      commission
      totalOrders
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      note
      createdBy
      createdByName
      updatedByName
      siteType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteSite = /* GraphQL */ `
  subscription OnDeleteSite {
    onDeleteSite {
      id
      name
      clientID
      contact_name
      contactName
      contact_phone
      phone_number
      email
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      isInsurance
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isActive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      companyID
      sendIdentification
      isAutoSubmitClaim
      sendExternalRequisition
      sendPrescription
      sendAuthorization
      sendOtherDocuments
      startBarCode
      uploadFileStatus
      hippa
      hippaFile
      resultTemplate
      isTesting
      street
      city
      state
      zipcode
      countryCode
      country
      setting
      defaultProvider
      practiceID
      labLocalID
      fax
      contact_fax
      discount
      commission
      totalOrders
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      note
      createdBy
      createdByName
      updatedByName
      siteType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient {
    onCreateClient {
      id
      name
      contact_name
      contact_phone
      phone_number
      country
      timezone
      fax_number
      street
      city
      state
      zipcode
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      resultTypes
      brmSetting
      setting
      pgName
      colLabId
      uploadFileStatus
      orderCounter
      requiredKeys
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contactName
          contact_phone
          phone_number
          email
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          isInsurance
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isActive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          companyID
          sendIdentification
          isAutoSubmitClaim
          sendExternalRequisition
          sendPrescription
          sendAuthorization
          sendOtherDocuments
          startBarCode
          uploadFileStatus
          hippa
          hippaFile
          resultTemplate
          isTesting
          street
          city
          state
          zipcode
          countryCode
          country
          setting
          defaultProvider
          practiceID
          labLocalID
          fax
          contact_fax
          discount
          commission
          totalOrders
          totalBilledAmount
          claimAmount
          openAmount
          paid
          lastOrder
          note
          createdBy
          createdByName
          updatedByName
          siteType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          first
          last
          middleName
          dob
          id_number
          clientID
          employeeFND
          street
          street2
          city
          state
          county
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insuranceGroupId
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          sendSMS
          sendEmail
          totalTest
          connectedTo
          createdBy
          createdByName
          insuranceDetails
          insuranceCompanyCode
          medicalNo
          postGridID
          noteAddedBy
          status
          isHospice
          snf
          isInsHousing
          eligibilityStatus
          requestedDetails
          primaryCarePhysician
          referringPyhsician
          renderingProvider
          referringProvider
          orderingProvider
          codes
          testTwo
          siteID
          setting
          ssn
          ethinicity
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient {
    onUpdateClient {
      id
      name
      contact_name
      contact_phone
      phone_number
      country
      timezone
      fax_number
      street
      city
      state
      zipcode
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      resultTypes
      brmSetting
      setting
      pgName
      colLabId
      uploadFileStatus
      orderCounter
      requiredKeys
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contactName
          contact_phone
          phone_number
          email
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          isInsurance
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isActive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          companyID
          sendIdentification
          isAutoSubmitClaim
          sendExternalRequisition
          sendPrescription
          sendAuthorization
          sendOtherDocuments
          startBarCode
          uploadFileStatus
          hippa
          hippaFile
          resultTemplate
          isTesting
          street
          city
          state
          zipcode
          countryCode
          country
          setting
          defaultProvider
          practiceID
          labLocalID
          fax
          contact_fax
          discount
          commission
          totalOrders
          totalBilledAmount
          claimAmount
          openAmount
          paid
          lastOrder
          note
          createdBy
          createdByName
          updatedByName
          siteType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          first
          last
          middleName
          dob
          id_number
          clientID
          employeeFND
          street
          street2
          city
          state
          county
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insuranceGroupId
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          sendSMS
          sendEmail
          totalTest
          connectedTo
          createdBy
          createdByName
          insuranceDetails
          insuranceCompanyCode
          medicalNo
          postGridID
          noteAddedBy
          status
          isHospice
          snf
          isInsHousing
          eligibilityStatus
          requestedDetails
          primaryCarePhysician
          referringPyhsician
          renderingProvider
          referringProvider
          orderingProvider
          codes
          testTwo
          siteID
          setting
          ssn
          ethinicity
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient {
    onDeleteClient {
      id
      name
      contact_name
      contact_phone
      phone_number
      country
      timezone
      fax_number
      street
      city
      state
      zipcode
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      resultTypes
      brmSetting
      setting
      pgName
      colLabId
      uploadFileStatus
      orderCounter
      requiredKeys
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contactName
          contact_phone
          phone_number
          email
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          isInsurance
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isActive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          companyID
          sendIdentification
          isAutoSubmitClaim
          sendExternalRequisition
          sendPrescription
          sendAuthorization
          sendOtherDocuments
          startBarCode
          uploadFileStatus
          hippa
          hippaFile
          resultTemplate
          isTesting
          street
          city
          state
          zipcode
          countryCode
          country
          setting
          defaultProvider
          practiceID
          labLocalID
          fax
          contact_fax
          discount
          commission
          totalOrders
          totalBilledAmount
          claimAmount
          openAmount
          paid
          lastOrder
          note
          createdBy
          createdByName
          updatedByName
          siteType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          first
          last
          middleName
          dob
          id_number
          clientID
          employeeFND
          street
          street2
          city
          state
          county
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insuranceGroupId
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          sendSMS
          sendEmail
          totalTest
          connectedTo
          createdBy
          createdByName
          insuranceDetails
          insuranceCompanyCode
          medicalNo
          postGridID
          noteAddedBy
          status
          isHospice
          snf
          isInsHousing
          eligibilityStatus
          requestedDetails
          primaryCarePhysician
          referringPyhsician
          renderingProvider
          referringProvider
          orderingProvider
          codes
          testTwo
          siteID
          setting
          ssn
          ethinicity
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateEmployee = /* GraphQL */ `
  subscription OnCreateEmployee {
    onCreateEmployee {
      id
      first
      last
      middleName
      dob
      id_number
      clientID
      employeeFND
      street
      street2
      city
      state
      county
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insuranceGroupId
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      sendSMS
      sendEmail
      totalTest
      connectedTo
      createdBy
      createdByName
      insuranceDetails
      insuranceCompanyCode
      medicalNo
      postGridID
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      testTwo
      siteID
      setting
      ssn
      ethinicity
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        requiredKeys
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateEmployee = /* GraphQL */ `
  subscription OnUpdateEmployee {
    onUpdateEmployee {
      id
      first
      last
      middleName
      dob
      id_number
      clientID
      employeeFND
      street
      street2
      city
      state
      county
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insuranceGroupId
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      sendSMS
      sendEmail
      totalTest
      connectedTo
      createdBy
      createdByName
      insuranceDetails
      insuranceCompanyCode
      medicalNo
      postGridID
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      testTwo
      siteID
      setting
      ssn
      ethinicity
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        requiredKeys
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteEmployee = /* GraphQL */ `
  subscription OnDeleteEmployee {
    onDeleteEmployee {
      id
      first
      last
      middleName
      dob
      id_number
      clientID
      employeeFND
      street
      street2
      city
      state
      county
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insuranceGroupId
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      sendSMS
      sendEmail
      totalTest
      connectedTo
      createdBy
      createdByName
      insuranceDetails
      insuranceCompanyCode
      medicalNo
      postGridID
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      testTwo
      siteID
      setting
      ssn
      ethinicity
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        requiredKeys
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTest = /* GraphQL */ `
  subscription OnCreateTest {
    onCreateTest {
      id
      status
      clientID
      labID
      employeeID
      siteID
      orderID
      baseSiteID
      testTypeID
      batch
      barcode
      owner
      result
      resultDetails
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      isRestated
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      updatedAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      isExternalTest
      externalTestFile
      testLocation
      isArchive
      arkstoneID
      arkstoneStatus
      sendToID
      sendToName
      sentToLabDate
      verifiedBy
      verifiedByName
      releaseDate
      releaseStatus
      resultPDF
      resultColor
      cancelReason
      _version
      _deleted
      _lastChangedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        fax_number
        user_id
        contact_email
        tubes_provided
        lab_director
        country
        referredDoctor
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        custom_notes
        invalid_notes
        indeterminate_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        logourl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        siteType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        requiredKeys
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        ssn
        ethinicity
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          requiredKeys
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      order {
        id
        employeeID
        orderNo
        providerID
        clientID
        labID
        labName
        clientName
        providerName
        siteID
        site_name
        employee_demographics
        testsToDo
        barcode
        diag_1
        diag_2
        diag_3
        diag_4
        diag_5
        diag_6
        diag_7
        diag_8
        diag_9
        diag_10
        diag_11
        diag_12
        proc_array
        provider
        orderDate
        collectionDate
        testDoneDate
        labReceiveDate
        status
        providerStatus
        cancelledReason
        notes
        isActive
        isResultEmailed
        floorNo
        requestID
        receivedBy
        receivedByName
        receivedDate
        updatedBy
        updatedByName
        createdBy
        createdByName
        timezone
        eligibilityStatus
        location
        tdDate
        externalID
        clincialComments
        hippaSignature
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTest = /* GraphQL */ `
  subscription OnUpdateTest {
    onUpdateTest {
      id
      status
      clientID
      labID
      employeeID
      siteID
      orderID
      baseSiteID
      testTypeID
      batch
      barcode
      owner
      result
      resultDetails
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      isRestated
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      updatedAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      isExternalTest
      externalTestFile
      testLocation
      isArchive
      arkstoneID
      arkstoneStatus
      sendToID
      sendToName
      sentToLabDate
      verifiedBy
      verifiedByName
      releaseDate
      releaseStatus
      resultPDF
      resultColor
      cancelReason
      _version
      _deleted
      _lastChangedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        fax_number
        user_id
        contact_email
        tubes_provided
        lab_director
        country
        referredDoctor
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        custom_notes
        invalid_notes
        indeterminate_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        logourl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        siteType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        requiredKeys
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        ssn
        ethinicity
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          requiredKeys
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      order {
        id
        employeeID
        orderNo
        providerID
        clientID
        labID
        labName
        clientName
        providerName
        siteID
        site_name
        employee_demographics
        testsToDo
        barcode
        diag_1
        diag_2
        diag_3
        diag_4
        diag_5
        diag_6
        diag_7
        diag_8
        diag_9
        diag_10
        diag_11
        diag_12
        proc_array
        provider
        orderDate
        collectionDate
        testDoneDate
        labReceiveDate
        status
        providerStatus
        cancelledReason
        notes
        isActive
        isResultEmailed
        floorNo
        requestID
        receivedBy
        receivedByName
        receivedDate
        updatedBy
        updatedByName
        createdBy
        createdByName
        timezone
        eligibilityStatus
        location
        tdDate
        externalID
        clincialComments
        hippaSignature
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTest = /* GraphQL */ `
  subscription OnDeleteTest {
    onDeleteTest {
      id
      status
      clientID
      labID
      employeeID
      siteID
      orderID
      baseSiteID
      testTypeID
      batch
      barcode
      owner
      result
      resultDetails
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      isRestated
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      updatedAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      isExternalTest
      externalTestFile
      testLocation
      isArchive
      arkstoneID
      arkstoneStatus
      sendToID
      sendToName
      sentToLabDate
      verifiedBy
      verifiedByName
      releaseDate
      releaseStatus
      resultPDF
      resultColor
      cancelReason
      _version
      _deleted
      _lastChangedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        fax_number
        user_id
        contact_email
        tubes_provided
        lab_director
        country
        referredDoctor
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        custom_notes
        invalid_notes
        indeterminate_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        logourl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        siteType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        requiredKeys
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        ssn
        ethinicity
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          requiredKeys
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      order {
        id
        employeeID
        orderNo
        providerID
        clientID
        labID
        labName
        clientName
        providerName
        siteID
        site_name
        employee_demographics
        testsToDo
        barcode
        diag_1
        diag_2
        diag_3
        diag_4
        diag_5
        diag_6
        diag_7
        diag_8
        diag_9
        diag_10
        diag_11
        diag_12
        proc_array
        provider
        orderDate
        collectionDate
        testDoneDate
        labReceiveDate
        status
        providerStatus
        cancelledReason
        notes
        isActive
        isResultEmailed
        floorNo
        requestID
        receivedBy
        receivedByName
        receivedDate
        updatedBy
        updatedByName
        createdBy
        createdByName
        timezone
        eligibilityStatus
        location
        tdDate
        externalID
        clincialComments
        hippaSignature
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLencoBarcodes = /* GraphQL */ `
  subscription OnCreateLencoBarcodes {
    onCreateLencoBarcodes {
      id
      labID
      clientID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLencoBarcodes = /* GraphQL */ `
  subscription OnUpdateLencoBarcodes {
    onUpdateLencoBarcodes {
      id
      labID
      clientID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLencoBarcodes = /* GraphQL */ `
  subscription OnDeleteLencoBarcodes {
    onDeleteLencoBarcodes {
      id
      labID
      clientID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTestConsent = /* GraphQL */ `
  subscription OnCreateTestConsent {
    onCreateTestConsent {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        siteType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        ssn
        ethinicity
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          requiredKeys
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTestConsent = /* GraphQL */ `
  subscription OnUpdateTestConsent {
    onUpdateTestConsent {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        siteType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        ssn
        ethinicity
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          requiredKeys
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTestConsent = /* GraphQL */ `
  subscription OnDeleteTestConsent {
    onDeleteTestConsent {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        siteType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        ssn
        ethinicity
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          requiredKeys
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTestTypes = /* GraphQL */ `
  subscription OnCreateTestTypes {
    onCreateTestTypes {
      id
      code
      name
      category
      custom_notes
      typeOfTest
      type
      totalTime
      firstAlert
      secondAlert
      units
      minValue
      maxValue
      refInterval
      duration
      hrCounter
      siteID
      clientID
      sources
      cptCodes
      ictCodes
      targetRanges
      isActive
      isAssociatedPanel
      isPanelOnly
      isARKStone
      lonicCode
      setting
      ranges
      createdByName
      updatedByName
      lastUsed
      location
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTestTypes = /* GraphQL */ `
  subscription OnUpdateTestTypes {
    onUpdateTestTypes {
      id
      code
      name
      category
      custom_notes
      typeOfTest
      type
      totalTime
      firstAlert
      secondAlert
      units
      minValue
      maxValue
      refInterval
      duration
      hrCounter
      siteID
      clientID
      sources
      cptCodes
      ictCodes
      targetRanges
      isActive
      isAssociatedPanel
      isPanelOnly
      isARKStone
      lonicCode
      setting
      ranges
      createdByName
      updatedByName
      lastUsed
      location
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTestTypes = /* GraphQL */ `
  subscription OnDeleteTestTypes {
    onDeleteTestTypes {
      id
      code
      name
      category
      custom_notes
      typeOfTest
      type
      totalTime
      firstAlert
      secondAlert
      units
      minValue
      maxValue
      refInterval
      duration
      hrCounter
      siteID
      clientID
      sources
      cptCodes
      ictCodes
      targetRanges
      isActive
      isAssociatedPanel
      isPanelOnly
      isARKStone
      lonicCode
      setting
      ranges
      createdByName
      updatedByName
      lastUsed
      location
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEmployeeBarCodes = /* GraphQL */ `
  subscription OnCreateEmployeeBarCodes {
    onCreateEmployeeBarCodes {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEmployeeBarCodes = /* GraphQL */ `
  subscription OnUpdateEmployeeBarCodes {
    onUpdateEmployeeBarCodes {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEmployeeBarCodes = /* GraphQL */ `
  subscription OnDeleteEmployeeBarCodes {
    onDeleteEmployeeBarCodes {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEmployeeTestCounter = /* GraphQL */ `
  subscription OnCreateEmployeeTestCounter {
    onCreateEmployeeTestCounter {
      id
      counter
      employeeCounter
      orderCounter
      patternQuestion
      totalTest
      employeeUpdate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEmployeeTestCounter = /* GraphQL */ `
  subscription OnUpdateEmployeeTestCounter {
    onUpdateEmployeeTestCounter {
      id
      counter
      employeeCounter
      orderCounter
      patternQuestion
      totalTest
      employeeUpdate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEmployeeTestCounter = /* GraphQL */ `
  subscription OnDeleteEmployeeTestCounter {
    onDeleteEmployeeTestCounter {
      id
      counter
      employeeCounter
      orderCounter
      patternQuestion
      totalTest
      employeeUpdate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateExternalTest = /* GraphQL */ `
  subscription OnCreateExternalTest {
    onCreateExternalTest {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateExternalTest = /* GraphQL */ `
  subscription OnUpdateExternalTest {
    onUpdateExternalTest {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteExternalTest = /* GraphQL */ `
  subscription OnDeleteExternalTest {
    onDeleteExternalTest {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEmployeeLogin = /* GraphQL */ `
  subscription OnCreateEmployeeLogin {
    onCreateEmployeeLogin {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEmployeeLogin = /* GraphQL */ `
  subscription OnUpdateEmployeeLogin {
    onUpdateEmployeeLogin {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEmployeeLogin = /* GraphQL */ `
  subscription OnDeleteEmployeeLogin {
    onDeleteEmployeeLogin {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTestSession = /* GraphQL */ `
  subscription OnCreateTestSession {
    onCreateTestSession {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTestSession = /* GraphQL */ `
  subscription OnUpdateTestSession {
    onUpdateTestSession {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTestSession = /* GraphQL */ `
  subscription OnDeleteTestSession {
    onDeleteTestSession {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDocuments = /* GraphQL */ `
  subscription OnCreateDocuments {
    onCreateDocuments {
      id
      clientID
      employeeID
      testID
      orderID
      docType
      docLabel
      fileName
      fileType
      fileSize
      notes
      isActive
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDocuments = /* GraphQL */ `
  subscription OnUpdateDocuments {
    onUpdateDocuments {
      id
      clientID
      employeeID
      testID
      orderID
      docType
      docLabel
      fileName
      fileType
      fileSize
      notes
      isActive
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDocuments = /* GraphQL */ `
  subscription OnDeleteDocuments {
    onDeleteDocuments {
      id
      clientID
      employeeID
      testID
      orderID
      docType
      docLabel
      fileName
      fileType
      fileSize
      notes
      isActive
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      employeeID
      orderNo
      providerID
      clientID
      labID
      labName
      clientName
      providerName
      siteID
      site_name
      employee_demographics
      testsToDo
      barcode
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      diag_9
      diag_10
      diag_11
      diag_12
      proc_array
      provider
      orderDate
      collectionDate
      testDoneDate
      labReceiveDate
      status
      providerStatus
      cancelledReason
      notes
      isActive
      isResultEmailed
      floorNo
      requestID
      receivedBy
      receivedByName
      receivedDate
      updatedBy
      updatedByName
      createdBy
      createdByName
      timezone
      eligibilityStatus
      location
      tdDate
      externalID
      clincialComments
      hippaSignature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      employeeID
      orderNo
      providerID
      clientID
      labID
      labName
      clientName
      providerName
      siteID
      site_name
      employee_demographics
      testsToDo
      barcode
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      diag_9
      diag_10
      diag_11
      diag_12
      proc_array
      provider
      orderDate
      collectionDate
      testDoneDate
      labReceiveDate
      status
      providerStatus
      cancelledReason
      notes
      isActive
      isResultEmailed
      floorNo
      requestID
      receivedBy
      receivedByName
      receivedDate
      updatedBy
      updatedByName
      createdBy
      createdByName
      timezone
      eligibilityStatus
      location
      tdDate
      externalID
      clincialComments
      hippaSignature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      employeeID
      orderNo
      providerID
      clientID
      labID
      labName
      clientName
      providerName
      siteID
      site_name
      employee_demographics
      testsToDo
      barcode
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      diag_9
      diag_10
      diag_11
      diag_12
      proc_array
      provider
      orderDate
      collectionDate
      testDoneDate
      labReceiveDate
      status
      providerStatus
      cancelledReason
      notes
      isActive
      isResultEmailed
      floorNo
      requestID
      receivedBy
      receivedByName
      receivedDate
      updatedBy
      updatedByName
      createdBy
      createdByName
      timezone
      eligibilityStatus
      location
      tdDate
      externalID
      clincialComments
      hippaSignature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          isRestated
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          updatedAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseDate
          releaseStatus
          resultPDF
          resultColor
          cancelReason
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateUploadRequestMain = /* GraphQL */ `
  subscription OnCreateUploadRequestMain {
    onCreateUploadRequestMain {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          employeeID
          clientID
          siteID
          requestID
          fileID
          fileName
          fileType
          floorNo
          uploadCallType
          data
          status
          reason
          processingStatus
          createdBy
          createdByName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUploadRequestMain = /* GraphQL */ `
  subscription OnUpdateUploadRequestMain {
    onUpdateUploadRequestMain {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          employeeID
          clientID
          siteID
          requestID
          fileID
          fileName
          fileType
          floorNo
          uploadCallType
          data
          status
          reason
          processingStatus
          createdBy
          createdByName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUploadRequestMain = /* GraphQL */ `
  subscription OnDeleteUploadRequestMain {
    onDeleteUploadRequestMain {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          employeeID
          clientID
          siteID
          requestID
          fileID
          fileName
          fileType
          floorNo
          uploadCallType
          data
          status
          reason
          processingStatus
          createdBy
          createdByName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateUploadRequest = /* GraphQL */ `
  subscription OnCreateUploadRequest {
    onCreateUploadRequest {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUploadRequest = /* GraphQL */ `
  subscription OnUpdateUploadRequest {
    onUpdateUploadRequest {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUploadRequest = /* GraphQL */ `
  subscription OnDeleteUploadRequest {
    onDeleteUploadRequest {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGeneralSettingType = /* GraphQL */ `
  subscription OnCreateGeneralSettingType {
    onCreateGeneralSettingType {
      id
      clientID
      code
      title
      label
      value
      isActive
      settingType
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGeneralSettingType = /* GraphQL */ `
  subscription OnUpdateGeneralSettingType {
    onUpdateGeneralSettingType {
      id
      clientID
      code
      title
      label
      value
      isActive
      settingType
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGeneralSettingType = /* GraphQL */ `
  subscription OnDeleteGeneralSettingType {
    onDeleteGeneralSettingType {
      id
      clientID
      code
      title
      label
      value
      isActive
      settingType
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUsers = /* GraphQL */ `
  subscription OnCreateUsers {
    onCreateUsers {
      id
      roles
      firstName
      lastName
      note
      email
      personalisation
      phone_number
      userRole
      clientID
      connectedID
      labID
      providerID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      labAdmin
      createdBy
      createdByName
      updatedBy
      updatedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUsers = /* GraphQL */ `
  subscription OnUpdateUsers {
    onUpdateUsers {
      id
      roles
      firstName
      lastName
      note
      email
      personalisation
      phone_number
      userRole
      clientID
      connectedID
      labID
      providerID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      labAdmin
      createdBy
      createdByName
      updatedBy
      updatedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUsers = /* GraphQL */ `
  subscription OnDeleteUsers {
    onDeleteUsers {
      id
      roles
      firstName
      lastName
      note
      email
      personalisation
      phone_number
      userRole
      clientID
      connectedID
      labID
      providerID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      labAdmin
      createdBy
      createdByName
      updatedBy
      updatedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessageTemplate = /* GraphQL */ `
  subscription OnCreateMessageTemplate {
    onCreateMessageTemplate {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessageTemplate = /* GraphQL */ `
  subscription OnUpdateMessageTemplate {
    onUpdateMessageTemplate {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessageTemplate = /* GraphQL */ `
  subscription OnDeleteMessageTemplate {
    onDeleteMessageTemplate {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
