import PopoverStick from "components/PopoverStick";
import { CONFIG, Audit_Parse } from "constant";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { formatLogDate, capitalizeLetter } from "utils";
import API from "api";
import { Storage } from "aws-amplify";
import FullImageView from "components/FullPageImageView";
import PopoverSticks from "components/PopoverSticks";
import TestLogsDetailView from "components/TestLogsDetailView";
import { toTitleCase } from "utils";
import { getContentType } from "utils";
import DocumentPreviewModal from "./DocumentPreviewModal";
import Loader from "components/Loader/Loader";

const TestLogsModal = (props) => {
  const { handleClose, test, setSuccessMessageText } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [image, setImage] = useState("");
  const [previewFileData, setPreviewFileData] = useState();


  const getSequenceNo = () => {
    if (data && data.length > 0) return CONFIG.isWhiteLabel ? data[0]?.barcode : data[0].sequenceNo;
    return "";
  };

  const showTestLogs = async () => {
    let logs = [];

    if (test.recordType === "employee") {
      logs = await API.getPatientLogs(test.mdID);
    } else if (test.recordType === "logs") {
      logs = await API.getLogs(test.id);
    } else if (test.sequenceNo) {
      logs = await API.getTestLogs(test.sequenceNo);
    } else if (test.ordID) {
      logs = await API.getOrderLogs(test.ordID, test.testID);
    }

    logs = logs.map((m) => {
      m.slug = m.action || m.slug;
      if (m.slug && m.slug.toLowerCase() === "email") {
        return { ...m, newValue: "Result Emailed" };
      }
      return { ...m };
    });
    setData([...logs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  };

  useEffect(() => {
    showTestLogs();
  }, []);

  const LABELS = {
    AccessionNoChanged: "Accession No. Changed",
  };

  const getActionDetails = (msg) => {
    let val = msg.slug || msg.action;
    return LABELS[val] || val;
  };

  const handleClickOnImage = async (fileName) => {

    try {
      if (!fileName) return;
      setLoading(true);

      const fileExtension = fileName.file.split(".").pop().toLowerCase();

      const contentType = getContentType(fileExtension);

      const result = await Storage.get(fileName.file, {
        bucket: "employee-external-tests",
        download: false,
        contentDisposition: "inline",
        contentType: contentType,
      });

      setPreviewFileData({ fileType: fileExtension === "pdf" ? "pdf" : "image", fileUrl: result });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  };


  // const handleClickOnImage = async (log) => {
  //   if (!log.file) return;
  //   try {
  //     const res = await Storage.get(log.file);
  //     console.log('Res',res)
  //     setImage(res);
  //   } catch (err) {
  //     console.log("Error:-", err.message);
  //   }
  // };

  function renderMessageContent(msg) {
    if (msg.file) {
      return msg.message;
    } else if (
      (msg.hasOwnProperty("changeRecord") && msg.changeRecord != null) ||
      (msg.hasOwnProperty("changeObject") && msg.changeObject != null)
    ) {
      return (
        <TestLogsDetailView
          item={msg.slug}
          values={msg?.changeRecord ? msg.changeRecord : msg.changeObject}
          changeObject={msg?.changeRecord ? msg.changeRecord : msg.changeObject}
          msg={msg}
        />
      );
    } else if (
      capitalizeLetter(msg.prevValue || msg.oldValue || "") &&
      capitalizeLetter(msg.newValue || msg.result || "")
    ) {
      return ` ${capitalizeLetter(msg.prevValue || msg.oldValue || "-")} to ${capitalizeLetter(
        msg.newValue || msg.result || "-"
      )}`;
    } else if (msg.action === "View Details") {
      return capitalizeLetter(msg.action);
    }

    return capitalizeLetter(msg.newValue || msg.result || msg.message || Audit_Parse[msg.eventType] || "-");
  }

  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${test.title || "Tests Logs"} ${(CONFIG.isWhiteLabel ? test.barcode : test.sequenceNo) || ""}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
      {loading && <Loader />}

        <div className="form-group-wrapper d-block">
          <div className="table-responsive">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th
                    className={`border-0`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    Sr#
                  </th>
                  <th
                    className={`border-0`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <span>Date</span>
                    </div>
                  </th>

                  {/* <th
                    className={`border-0`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    Test Name
                  </th> */}

                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <span>Action</span>
                    </div>
                  </th>

                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>Event</span>
                    </div>
                  </th>

                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>User</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((msg, index) => {
                    return (
                      <tr key={index}>
                        <td>{data.length - index}</td>
                        <td>{formatLogDate(msg.createdAt)}</td>

                        {/* <td>{formatLogDate(msg.createdAt)}</td> */}

                        {msg.slug === "Test Updated" && msg?.changeObject ? (
                          <PopoverStick
                            item={msg.slug}
                            values={Object.keys(msg.changeObject)}
                            changeObject={msg.changeObject}
                          />
                        ) : (
                          <td style={{ textAlign: "center" }}>{getActionDetails(msg)}</td>
                        )}
                        {/* <td style={{ textAlign: "center" }}>{getActionDetails(msg)}</td> */}

                        <td
                          className={msg.file && "linkedTextU"}
                          style={{ textAlign: "center" }}
                          onClick={() => handleClickOnImage(msg)}
                        >
                          {renderMessageContent(msg)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {msg.userName === "Auto" ? "System Generated" : toTitleCase(msg.userName) || "N/A"}
                        </td>
                        {window.location.hostname === "localhost" && (
                          <td
                            onClick={async () => {
                              await API.deleteLogRecord(msg);
                              setSuccessMessageText("delete Successfully");
                              // appContext.showSuccessMessage("delete Successfully");
                              await showTestLogs();
                            }}
                          >
                            <i className="fas fa-trash cursor-pointer" />
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
      {/* {image && <FullImageView image={image} handleClose={() => setImage("")} />} */}


      <DocumentPreviewModal
        isModalOpen={previewFileData?.fileType}
        setIsModalOpen={() => {
          setPreviewFileData();
        }}
        cssClass="preview-modal"
        fileUrl={previewFileData?.fileUrl}
        fileType={previewFileData?.fileType}
      />
    </Modal>
  );
};

export default TestLogsModal;
