import TabPills from "components/Tabs/TabPills";
import {
  DIAGNOSIS_CODE,
  LAB_CLIENT,
  PAYER_MANAGEMENT,
  PROCEDURE_CODE,
  PROVIDER,
  SYSTEM_USERS,
  TEST_SETTING,
  TEST_SETTING_PANEL,
  TEST_DICTIONARY,
  MESSAGE_TEMPLATE,
  REFERENCE,
} from "constants/tabPillsContants";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
// import { fetchCompanySetting } from "store/features/companySetting/companySettingSlice";
import { providerList } from "store/features/providers/providersSelectors";
import { usersList } from "store/features/users/userSelectors";
// import ClaimInvoiceAPI from "views/ClaimInvoice/ClaimInvoiceAPI";
import DiagnosisCodeSettings from "views/GeneralSettings/DiagnosisCodeSettings";
import Insurances from "views/GeneralSettings/Insurances";
import ProcedureCodeSettings from "views/GeneralSettings/ProcedureCodeSettings";
// import MessageSetting from "views/MessageSetting/MessageSetting";
// import MessageTemplateView from "views/MessageTemplate/MessageTemplateView";
import UsersViewWhiteLabel from "views/GraphQL/UsersViewWhiteLabel";
import LabClient from "views/LabClient/LabClient";
import Provider from "views/Provider";
import TestTypeSetting from "views/TestTypeSetting/TestTypesSetting.js";
import TestDictionary from "views/GeneralSettings/TestDictionary";
import {
  SETTINGS,
  CLIENT_MANAGEMENT,
  PAYER_MANAGEMENT_VALUE,
  DIAGNOSIS_CODES,
  PROCEDURE_CODES,
  SYSTEM_USERS_VALUE,
  TESTS_MANAGEMENT,
  TESTS_DICTIONARY,
  PROVIDER_MANAGEMENT,
  MESSAGE_TEMPLATE_VALUE,
} from "constant";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import MessageTemplateView from "views/MessageTemplate/MessageTemplateView";
import TestPanelsView from "./TestPanels/TestPanelsView";
import ReferenceView from "views/GraphQL/RefernceLab/RefernceLab";

const LabTestTypesSetting = () => {
  const [tabsOptions, setTabOptions] = useState([]);
  const appContext = useContext(AppContext);
  const providers = useSelector(providerList);
  const usersListArr = useSelector(usersList);
  const user = useSelector(selectLoginUser);
  const dispatch = useDispatch();
  const permission = useSelector(userGivenPermission);

  const isLocalhost = window.location.href.includes("localhost");

  useEffect(() => {
    setTabOptions(
      appContext.permission?.users?.access && appContext.user?.isAdmin() ? tabPillsConstants : tabPillsConstants
      // : tabPillsConstants.filter((f) => f.title !== SYSTEM_USERS)
    );
  }, [providers, usersListArr, appContext.permission, user]);

  // useEffect(() => {
  //   dispatch(fetchCompanySetting());
  // }, []);

  const tabPillsConstants = [
    permission[PROVIDER_MANAGEMENT]?.access && {
      title: PROVIDER,
      length: providers?.length,
      component: <Provider />,
    },
    permission[CLIENT_MANAGEMENT]?.access && {
      title: LAB_CLIENT,
      component: <LabClient />,
    },
    permission[PAYER_MANAGEMENT_VALUE]?.access && {
      title: PAYER_MANAGEMENT,
      component: <Insurances />,
    },
    permission[DIAGNOSIS_CODES]?.access && {
      title: DIAGNOSIS_CODE,
      component: <DiagnosisCodeSettings />,
    },
    permission[PROCEDURE_CODES]?.access && {
      title: PROCEDURE_CODE,
      component: <ProcedureCodeSettings />,
    },
    permission[SYSTEM_USERS_VALUE]?.access && {
      title: SYSTEM_USERS,
      component: <UsersViewWhiteLabel />,
    },

    permission[TESTS_MANAGEMENT]?.access && {
      title: TEST_SETTING_PANEL,
      component: <TestPanelsView />,
    },

    permission[TESTS_MANAGEMENT]?.access && {
      title: TEST_SETTING,
      component: <TestTypeSetting />,
    },

    permission[TESTS_DICTIONARY]?.access && {
      title: TEST_DICTIONARY,
      component: <TestDictionary />,
    },
    permission[MESSAGE_TEMPLATE_VALUE]?.access &&
      isLocalhost && {
        title: MESSAGE_TEMPLATE,
        component: <MessageTemplateView />,
      },
    permission[CLIENT_MANAGEMENT]?.access && {
      title: REFERENCE,
      component: <ReferenceView />,
    },
  ].filter(Boolean); // Filters out any falsey values, such as null or undefined

  useEffect(() => {
    setTabOptions(
      appContext.permission?.users?.access && appContext.user?.isAdmin() ? tabPillsConstants : tabPillsConstants
      // : tabPillsConstants.filter((f) => f.title !== SYSTEM_USERS)
    );
  }, [providers, usersListArr, appContext.permission, user]);

  return (
    <div className="shows-screen-parent custom-lab-test">
      <div style={{ flex: 1 }}>
        <Row>
          <Col md="12">
            <div className="system-settings-wrapper">
              <TabPills tabs={tabsOptions} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LabTestTypesSetting;
